/* ------------------------------------------------------------------------------------------------ */
/* Common */
/* ------------------------------------------------------------------------------------------------ */

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* Common */

hr {
  border: 0;
  border-top: 1px solid #e2e2e2; }

/* ------------------------------------------------------------------------------------------------ */
/* Typography */
/* ------------------------------------------------------------------------------------------------ */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  &::-webkit-scrollbar {
    width: 5px; }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px; }
  &::-webkit-scrollbar-thumb {
    background: rgba($red,.8);
    border-radius: 5px;
 }    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(#333,.4); } }


p {
  margin: 0;
  padding: 0;
  strong,b {
    font-weightL bold {} }
  em,i {
    font-style: italic; }
  u {
    text-decoration: underline; } }

h1, h2, h3, h4 {
  margin: 0 0 10px;
  line-height: normal;
  font-weight: 400; }

article {
  p {
    word-break: break-word;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    clear: both; }
  a {
    &:visited {
      text-decoration: underline; }
    &:hover {
      text-decoration: none; } }
  blockquote {
    font-style: italic;
    &:before {
      content: "\201C";
      display: inline-block;
      padding-right: 0.4em; } }
  li {
    ul, ol {
      margin: 0 1.5em; } }
  ul, ol {
    margin: 0 0 15px;
    padding-left: 30px;
    &:last-child {
      margin: 0; } }

  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: upper-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } }
  h1,h2,h3,h4,h5,h6 {
    color: $primary !important;
    margin: 0 0 15px;
    font-weight: 500; }
  h1 {
    font-size: 24px; }
  h2 {
    font-size: 21px; }
  h3 {
    font-size: 18px; }
  h4 {
    font-size: 15px; }
  h5 {
    font-size: 12px; }
  h6 {
    font-size: 10px; }

  li {
    margin-bottom: 10px; } }

ul,ol {
  padding: 0;
  margin: 0; }

img {
  max-width: 100%;
  &.aligncenter {
    display: block;
    margin: auto; }
  &.alignright {
    float: right;
    clear: both;
    margin: 0 0 15px; } }



ul {
  list-style: none; }
label {
  font-weight: normal; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

html, body {
  font-weight: 400;
  font-family: "TradeGothic LT",sans-serif;
  // font-size: 15px
  // line-height: 24px
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  color: $primary;
  background: #F9F9F9;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  input {
    font-family: inherit; } }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

input {
  &:focus {
    outline: none;
    box-shadow: none; } }

main {
  overflow: hidden; }

button {
  color: inherit; }





