@import 'cores/mixin';


// Color
$primary: #000000;
$purple: #5315FF;
$green: #56EAB6;
$green-text: #2BC656;
$red: #CA0538;



/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/base';
@import 'cores/layout';


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/general';
@import 'components/header';
@import 'components/form';
@import 'components/content';
@import 'components/footer';
@import 'components/responsive';
