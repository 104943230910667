/* ------------------------------------------------------------------------------------------------ */
/* Content */
/* ------------------------------------------------------------------------------------------------ */

.hero {
  max-height: 85vh;
  min-height: 80vh;
  padding-top: 240px;
  // background: linear-gradient(0deg, #F5FDFF 0%, #B3D9E4 100%)
  background: linear-gradient(0deg, #e4faff 0%, #B3D9E4 100%);
  position: relative;
  padding-bottom: 13vw;
  &:before {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 4.167vw;
    border-bottom: 4.167vw solid #f9f9f9;
    border-left: 50vw solid transparent;
    border-right: 50vw solid $red;
    border-top: 4.167vw solid transparent;
    left: 0;
    right: 0;
    z-index: 2; }
  &_pattern {
    @include absfull;
    background: url('../images/hero-pattern.svg') no-repeat center;
    background-size: cover; }
  &_img {
    @include absfull;
    background: no-repeat center;
    background-size: cover; }
  &_img {
    left: auto;
    width: 47%;
    bottom: 3vw;
    background-size: 100% auto;
    background-position: center bottom; }

  .container {
    position: relative; }
  article {
    max-width: 600px; }
  h1 {
    font-size: 48px;
    line-height: 58px;
    font-weight: bold;
    margin: 0 0 16px; }
  p {
    font-size: 21px;
    line-height: 30px; }

  &_slider {
    position: relative;
    padding-top: 168px;
    padding-bottom: 10vw;
    min-height: auto;
    max-height: initial;
    z-index: 1;
    overflow: hidden;
    &:before {
      border-bottom: 5.167vw solid #f9f9f9;
      border-left: 60vw solid transparent;
      border-right: 40vw solid $red;
      border-top: 3.167vw solid transparent;
      z-index: 5; }
    .slider-custom-dot .owl-dots {
      bottom: -30px; }
    .hero_pattern {
      z-index: 1; }
    .container {
      padding: 0; }
    .slider {
      position: relative;
      z-index: 2; }
    .hero_item {
      position: relative;
      box-shadow: 0 20px 60px 0 rgba(0,0,0,0.25);
      z-index: 3;
      .bg_img {
        background-size: cover;
        background-position: center;
        // position: relative
        background-color: #EFF3F7;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1; }
      .content {
        z-index: 3;
        position: relative;
        min-height: 480px;
        max-width: 37%;
        top: 0;
        left: 0;
        padding: 96px 0 90px 70px;
        * {
          position: relative;
          z-index: 2; }
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: calc(100% + 120px);
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(90deg, rgba(255,255,255,.75) 10%, rgba(255, 255, 255, 0) 100%);
          z-index: 1; } } }


    &.overlay {
      .hero_item:after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.4);
        z-index: 1; }
      .content {
        color: #FFF;
        display: flex;
        align-items: center; } } }
  &_detail {
    min-height: 950px;
    max-height: none;
    //padding-bottom: 8vw
    .container {
      position: relative;
      z-index: 2;
      &> .row {
        margin-bottom: -8vw;
        height: 100%; } }
    h1 {
      // max-width: 409px
      margin: 0 0 40px; }
    h3 {
      font-size: 26px;	line-height: 31px;
      margin: 0 0 16px;
      font-weight: bold; }
    p {
      font-size: 18px;
      line-height: 32px; }
    &_img {
      box-shadow: 0 20px 60px 0 rgba(0,0,0,0.25);
      @include imgBg(53.57% 0); }
    .col1 {
      > .row {
        height: 100%; } }
    .img2 {
      position: relative;
      top: 32px;
      height: calc(100% - 64px);
      display: flex;
      &:before {
        padding: calc(71.43% - 32px) 0; } }
    .img3 {} }

  &.hero-about {
    .hero_pattern {
      background: url('../images/about-pattern.svg') no-repeat center; } }

  &-about {
    min-height: auto !important;
    max-height: inherit !important;
    padding-bottom: 400px;
    padding-top: 200px;
    &:before {
      border-bottom: 5.167vw solid #f9f9f9;
      border-left: 60vw solid transparent;
      border-right: 40vw solid $red;
      border-top: 3.167vw solid transparent; }
    .hero_img {
      width: 93%; }
    .hero_img {
      bottom: 0.5vw; }
    h1 {
      color: $red !important; }
    p {
      font-size: 18px;
      line-height: 32px; }
    article {
      margin-left: auto; }
    .sub {
      color: #636362;
      font-size: 21px;
      line-height: 30px;
      display: block;
      margin: 0;
      font-weight: bold; } } }


.hs1 {
  padding: 44px 0;
  .title {
    margin-bottom: 22px; } // 32px
  &_item {
    @include imgBg(72.92% 0);
    position: relative;
    color: #fff;
    &:hover {
      .hs1_bg {
        // transform: scale(1.1)
        margin: -5%;
        width: 110%;
        height: 110%;
        &:before {
          opacity: 1; } }
      .hs1_desc {
        opacity: 1;
        max-height: 400px; }
      .hs1_text:after {
        opacity: 1;
        right: 0; } } }
  &_bg {
    @include bgcenter;
    @include absfull;
    transition: all .3s linear;
    width: 100%;
    height: 100%;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 61.42%;
      width: 100%;
      // background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 20%);
      // background: linear-gradient(0deg, black 0%, rgba(255, 255, 255, 0) 25%)
      // background: rgba(0,0,0,.2)
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      opacity: 1; } }
  &_nation {
    display: none; }
  &_desc {
    opacity: 0;
    transition: all .5s;
    max-height: 0; }
  &_text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    // opacity: 0
    transition: all .5s;
    padding-right: 20px;
    font-size: 16px;
    line-height: 24px;
    &:after {
      content: "";
      position: absolute;
      right: 25px;
      opacity: 0;
      bottom: 4px;
      height: 13px;
      width: 29px;
      background: url("../images/white-long-arrow.svg") no-repeat center;
      transition: all .5s; } }
  &_name {
    font-size: 24px;	font-weight: bold;	line-height: 36px; }

  .slider {
    justify-content: center;
    .item {
      padding-left: 15px;
      padding-right: 15px;
      display: block !important; }
    &:not(.owl-loaded) {
      .item {
        max-width: 25%;
        flex: 0 0 25%;
        &:nth-child(4) {
          display: none !important; } } }
    &.no-slider {
      padding-top: 22px; }
    .owl-prev {
      left: -50px; }
    .owl-next {
      right: -50px; } }
  .owl-stage-outer {
    padding: 22px 0 30px; } }

.hs2 {
  padding: 24px 0 48px;
  position: relative;
  &_pattern {
    position: absolute;
    top: -30.147%;
    left: 0;
    right: 0;
    height: 100%;
    background: url("../images/body-pattern.svg") no-repeat left top; }
  .container {
    position: relative; }
  h3 {
    font-size: 21px;	line-height: 30px;
    font-weight: bold; }

  &_title {
    margin: 0 0 44px; } // 0 0 64px
  &_box {
    @include imgBg(20.42% 0);
    position: relative;
    margin-bottom: 0;
    transition: margin .3s ease;
    &.open {
      margin-bottom: 88px;
      .hs2_bg {
        opacity: 0; }
      .hs2_col + .hs2_col {
        border: 0; } } }
  &_bg,&_cat,&_content,&_itemtext,&_slider {
    @include absfull; }
  &_bg {
    @include bgcenter;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 61.42%;
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%); } }
  &_cat {
    color: #fff;
    .row {
      height: 100%; } }

  &_col {
    height: 100%;
    cursor: pointer;
    transition: all .5s;
    &:nth-child(6):after {
      transition-delay: 0.8s; }
    &:nth-child(5):after {
      transition-delay: 0.9s; }
    &:nth-child(4):after {
      transition-delay: 1s; }
    &:nth-child(3):after {
      transition-delay: 1.1s; }
    &:nth-child(2):after {
      transition-delay: 1.2s; }
    &:nth-child(1):after {
      transition-delay: 1.3s; }
    &:before {
      content: "";
      background: transparent;
      transition: all .5s;
      @include absfull; }
    &:after {
      content: "";
      background: #f9f9f9;
      transition: all .5s;
      opacity: 0;
      @include absfull;
      right: -1px;
      transition: all .5s; }
    &:hover:before {
      background: rgba(#000,.2); }
    &+ .hs2_col {
      border-left: 1px solid rgba(#fff,.5); } }

  &_coltext {
    position: absolute;
    bottom: 32px;
    left: 24px;
    right: 24px; }
  &_content {
    background: transparent;
    margin: 0 -12px;
    color: #fff;
    .owl-dots {
      margin: 24px 0 36px;
      text-align: center;
      line-height: 4px; }
    .owl-dot {
      height: 4px;
      width: 64px;
      background: #D8D8D8;
      border-radius: 0;
      &.active {
        background: #B4B4B4; } } }
  &_slider,&_content {
    opacity: 0;
    visibility: hidden;
    transition: all .5s; }
  &_slider {
    transition: all 1s;
    &:not(.owl-loaded) {
      display: flex;
      position: relative;
      .hs2_item {
        max-width: calc(33.33% - 24px);
        flex: 0 0 calc(33.33% - 24px);
        display: block !important; } } }
  &_item {
    @include imgBg(63.775% 0);
    margin: 0 12px;
    position: relative;
    transform: translateX(30px);
    transform-origin: 0 0;
    opacity: 0;
    visibility: hidden;
    transition: all 1s;
    font-size: 16px;
    line-height: 24px;
    &text {
      padding: 24px;
      top: auto;
      background: linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 100%); }
    h3 {
      margin: 0; }
    p {
      max-width: 280px;
      max-height: 0;
      opacity: 0;
      transition: all .5s ease; }
    &:hover {
      p {
        max-height: 100%;
        opacity: 1; } } }
  .not-found {
    width: calc(100% - 12px);
    margin: 0 12px;
    padding: 18.8%;
    background: #f2f2f9;
    // border: 1px solid #BEBEBE
    color: #636362;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 36px;
    position: absolute;
    top: 0;
    left: 0; }
  &_nav {
    position: absolute;
    z-index: 2;
    bottom: -114px;
    left: 0;
    right: 0;
    border-radius: 4px;
    transition: all .5s;
    opacity: 0;
    transform: translateY(30px);
    visibility: hidden;
    a {
      line-height: 50px;
      background: #D8D8D8;
      // font-size: 21px
      font-size: 14px;
      line-height: 20px;
      color: #636362;
      text-align: left;
      border: 1px solid #BEBEBE;
      padding: 8px 36px 8px 12px !important;
      display: flex;
      align-items: center;
      &:hover {
        background: #CDCDCD; }
      .svg {
        position: absolute;
        top: calc(50% - 10px);
        right: 12px; }
      &+ a {}

      &:not(.active) {
        .svg {
          path {
            fill: #777; } } }
      &.active {
        background: $red;
        color: #fff;
        .svg {
          transform: rotate(45deg); } } } }
  &_box.open {
    .hs2_col {
      &:after {
        opacity: 1; }
      &:nth-child(1):after {
        transition-delay: 0s; }
      &:nth-child(2):after {
        transition-delay: 0.1s; }
      &:nth-child(3):after {
        transition-delay: 0.2s; }
      &:nth-child(4):after {
        transition-delay: 0.3s; }
      &:nth-child(5):after {
        transition-delay: 0.4s; }
      &:nth-child(6):after {
        transition-delay: 0.5s; } }
    .hs2_nav,.hs2_content,.hs2_slider.open,.hs2_slider.open .hs2_item {
      transition-delay: 0.7s;
      opacity: 1;
      transform: none;
      visibility: visible; } } }

.hs3 {
  padding: 136px 0;
  position: relative;
  @include bgcenter;
  color: #fff;
  text-align: center;
  &:before,&:after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    border-top: 9.375vw solid #f9f9f9;
    //border-left: 50vw solid #fff
    border-right: 100vw solid transparent; }
  &:after {
    top: auto;
    bottom: -1px;
    transform: rotate(-180deg); }
  h2 {
    font-size: 48px;
    line-height: 58px;
    color: #fff;
    margin: 0 0 32px; }
  .btn {
    margin-bottom: 88px; }
  .row {
    justify-content: center;
    margin-bottom: 0 !important; }
  &_item {
    position: relative;
    padding: 50% 0;
    background: url("../images/circle-arrow.svg") no-repeat center;
    background-size: cover;
    margin-bottom: 24px; }
  &_content {
    @include absfull;
    padding: 30.5% 15px; }
  &_num {
    font-size: 34px; //48px
    line-height: 42px; //58px
    font-weight: bold; }
  &_text {
    max-width: 160px;
    margin: auto;
    font-size: 18px;
    line-height: 26px; }
  .note {
    text-align: center;
    padding: 64px 12px 0;
    font-style: italic; } }

.hs4 {
  padding: 48px 0 60px;
  &_item {
    @include imgBg(38.46% 0);
    margin: -1px 0; }
  &_slider .owl-stage-outer {
    box-shadow: 0 20px 60px 0 rgba(0,0,0,0.25); } }

.wocas {
  font-size: 16px;
  line-height: 24px;
  .title {
    margin-bottom: 0;
    h2 {
      margin-bottom: 0; } }
  .job-title {
    &:not(:last-child) {
      margin-bottom: 20px; } }
  .caption {
    padding: 16px;
    // border: 1px solid #e2e2e2
    border-radius: 6px;
    background: #f2f2f2; }
  &_item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0 60px;
    &_img {
      position: relative;
      width: 100%;
      max-width: 33.333333%;
      flex: 0 0 33.333333%;
      .img {
        @include imgBg(50% 0);
        background-color: #f9f9f9;
        box-shadow: 0 10px 30px 0 rgba(0,0,0,0.25); } }
    &_content {
      position: relative;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      padding-left: 64px;
      .name {
        font-weight: bold; } } }
  .owl {
    &-prev, &-next {
      top: calc(50% - 84px); }
    &-prev {
      left: 0; }
    &-next {
      right: 0; } }

  .owl-dots {
    position: relative;
    bottom: 60px;
    left: 0;
    width: 100%;
    margin: 42px 0 0;
    text-align: center;
    line-height: 4px; }
  .owl-dot {
    height: 4px;  width: 64px;  background: #D8D8D8;
    border-radius: 0;
    &.active {
      background: #B4B4B4; } } }

.git {
  color: #fff;
  background: #636362;
  position: relative;
  margin-top: 4.687vw;
  font-size: 16px;
  line-height: 24px;
  .container {
    padding-top: 60px;
    padding-bottom: 60px; }
  &:before {
    content: "";
    position: absolute;
    top: calc(-4.687vw + 1px);
    border-bottom: 4.687vw solid $red;
    border-right: 81.88vw solid transparent; }
  h2 {
    color: #fff; }
  h3 {
    font-size: 26px;
    line-height: 31px;
    margin: 0 0 8px;
    font-weight: bold; }
  .title {
    max-width: 600px; }
  p:not(:last-child) {
    margin: 0 0 35px; }
  &_box1 {
    @include imgBg(40% 0);
    position: relative;
    height: 100%;
    &:after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.2);
      background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 100%); }
    .git_content {
      @include absfull;
      z-index: 2; }
    a.with-arrow {
      position: relative;
      z-index: 3; } }
  &_box {
    background: #434343;
    position: relative;
    overflow: hidden;
    .watermark {
      position: absolute;
      height: 137px;
      width: 284px;
      top: 45px;
      right: -47px;
      z-index: 1; }
    h3,p, a.with-arrow {
      position: relative;
      z-index: 2; }
    a.with-arrow {
      z-index: 3; }
    &.red {
      background: $red;
      // &:before
 }      //   background-image: url("../images/red-pattern.svg")
    &+ .git_box {
      margin: 24px 0 0; } }

  &_content {
    padding: 24px;
    position: relative; }
  &_wos {
    // height: 0
    h3 {
      margin: 0 0 24px; }
    // &-wrap
    //   padding-bottom: 50px
    .more {
      position: absolute;
      bottom: 0;
      left: 12px;
      color: #FFF;
      cursor: pointer;
      display: none;
      &:hover {
        text-decoration: underline; } }
    &-says {
      overflow: hidden;
      &.scrollAble {
        padding-right: 20px;
        transition: all .3s ease;
        &.show {
          overflow-y: auto; }
        // &:not(.show)
        //   .git_say:not(:nth-child(1)):not(:nth-child(2))
        //     display: none
        .more {
          display: block; } } } }

  &_say {
    opacity: 0.75;
    padding: 16px 0;
    // &+ .git_say
    //// padding: 32px 0 0
    // p
    //   font-style: italic
    p:not(:last-child) {
      margin: 0 0 12px; } }
  &_name {
    font-weight: bold;
    display: block; }
  &_job,
  &_company {
    display: block; } }

.ctus {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 6vw;
  &:before {
    content: "";
    position: absolute;
    height: 6vw;
    border-bottom: 0 solid transparent;
    border-left: 80vw solid $red;
    border-right: 50vw solid transparent;
    border-top: 6vw solid transparent;
    top: 0;
    left: 0;
 }    // z-index: -1
  &_content {
    position: relative;
    z-index: 3;
    padding: 135px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &:after {
      content: "";
      background: linear-gradient(180deg, rgba(0,0,0,0.41) 0%, rgba(0,0,0,0.6) 100%);
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
    .container {
      position: relative;
      z-index: 2; } }
  .title {
    margin-bottom: 0;
    h2 {
      font-size: 42px;
      line-height: 51px;
      max-width: 500px;
      margin: 0 auto 24px;
      color: #FFF; } } }


.haq {
  position: fixed;
  bottom: 32px;
  right: 32px;
  background: #fff;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.25);
  z-index: 10;
  max-width: 220px;
  h3 {
    color: #CA0538;
    font-size: 21px;
    line-height: 30px;
    margin: 0;
    font-weight: bold; }
  p {
    color: #636362;
    margin: 0 0 16px; }
  .btn {
    width: 100%;
    text-transform: uppercase; } }

// events page
.es1 {
  padding-top: 48px;
  padding-bottom: 44px;
  .title {
    margin-bottom: 44px; }
  &_img {
    position: relative;
    width: 100%;
    max-width: 515px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    transform: rotate(20deg);
    #shape01 {
      transform: rotate(-90deg); }
    #shape03 {
      transform: rotate(180deg); }
    #shape04 {
      transform: rotate(90deg); }
    #curved01 {
      transform: rotate(43deg); }
    .img {
      &_logo {
        position: absolute;
        z-index: 2;
        width: 68%;
        // padding-top: 53.669%
        left: 16%;
        top: 16%;
        transform: rotate(-20deg);
        border-radius: 50%;
        border: 6px solid #FFF;
        overflow: hidden;
        .img {
          display: none;
          position: relative;
          // left: 0
          // top: 0
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          object-fit: cover;
          &.active {
            display: block; } } }
      &_item {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 50%;
        flex: 0 0 50%;
        cursor: pointer;
        opacity: 1;
        transform: none;
        opacity: 1;
        transition: all .5s;
        .box-wrap {
          width: 100%;
          padding-top: 100%; }
        svg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1; }
        // &:hover,
        &.active {
          transform: scale(1.1);
          .img_text {
            font-size: 30px;
            line-height: 40px; } }
        &:not(.active):hover {
          opacity: .7; } }
      &_text {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        transform: rotate(45deg);
        top: 12px;
        right: 12px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 37%;
        font-size: 24px;
        line-height: 36px;
        transition: all .5s;
        &.it2 {
          transform: rotate(45deg) scale(-1);
          padding-top: 37%;
          padding-bottom: 0; }
        span {
          color: #FFF;
          font-weight: bold;
          display: block;
          text-align: center; } } } }


  &_wrap {
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 36px;
      line-height: 43px;
      // color: #2284FF
      font-weight: bold;
      margin-bottom: 16px; }
    .col-left,
    .col-right {
      margin-bottom: 20px; } }

  .item.collapse {
    display: none;
    &.show {
      display: block; } } }

.es2 {
  position: relative;
  &_pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: url("../images/body-pattern.svg") no-repeat center; }
  .title {
    margin-bottom: 32px; }
  &_item {
    justify-content: center;
    align-items: center;
    margin-bottom: 44px;
    &-content {
      padding-left: 50px;
      h3 {
        font-size: 21px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 4px; }
      .date {
        display: block;
        margin-bottom: 16px; } }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .es2_item-content {
        padding-left: 0;
        padding-right: 100px; } } } }

.es3 {
  margin-bottom: 50px;
  .title {
    margin-bottom: 32px;
    h2 {
      margin-bottom: 0; } }
  &_logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    justify-content: center;
    &_item {
      position: relative;
      width: 100%;
      max-width: 16.666666%;
      flex: 0 0 16.666666%;
      display: flex;
      padding: 0 12px;
      height: 100px;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      img {
        max-width: 100%; } } } }


.fs {
  padding-top: 44px;
  &_title {
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 24px;
    font-weight: bold; } }
.fs1 {
  padding-bottom: 60px;
  .title {
    margin-bottom: 32px;
    text-align: left;
    p {
      max-width: 600px;
      margin: 0; } } }

.fsside {
  margin-bottom: 60px; }


.ct {
  padding-top: 44px; }

.ct1 {
  margin-bottom: 60px; }
.ctside {
  margin-bottom: 60px; }
.contact-list {
  .item {
    display: flex;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 48px; }
    &_img {
      position: relative;
      display: block;
      width: 100%;
      max-width: 34%;
      flex: 0 0 34%;
      .img {
        width: 100%;
        padding: 38%;
        background-color: #e2e2e2;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; } }
    &_title {
      font-size: 21px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 16px; }
    &_content {
      position: relative;
      width: 100%;
      max-width: 66%;
      flex: 0 0 66%;
      padding-left: 24px;
      p {
        margin-bottom: 8px; }
      .link {
        margin-bottom: 4px;
        a {
          display: inline-block;
          transition: all .3s ease;
          &:hover {
            color: $red; } } }
      .tel a {
        color: #212121; }
      .mailto a {
        color: #636362; } } } }

.hs2 {
  &.pd {
    padding-top: 60px;
    @include maxtablet {
      padding-bottom: 120px; }
    @include mobile {
      padding-bottom: 150px; } }
  &_title {
    text-align: center;
    p {
      max-width: 856px;
      margin-left: auto;
      margin-right: auto; } }
  .slider:not(.open) {
    position: absolute; }
  &_item {
    max-height: 500px; }
  &_nav {
    @include maxtablet {
      bottom: -40px; } }
  // .hs2_bg:before,
  .hs2_pattern {
 } }    // display: none


.pd1 {
  padding: 88px 0 44px;
  &_img {
    // +imgBg(52.42% 0)
    // background-color: transparent
    // background-size: contain
    // background-position: top right
    // margin-top: 36px
    padding-top: 60px;
    img {
      width: 100%; } }
  &_img-text {
    padding-top: 24px;
    font-size: 16px;
    line-height: 24px; }
  h2 {
    margin: 0 0 16px; }
  dl {
    margin: 0 0 32px; }
  dt,dd {
    padding: 12px 0;
    margin: 0; }
  dt {
    float: left;
    width: 160px;
    font-weight: 400; }
  dd {
    padding-left: 108px;
    border-bottom: 1px solid #E2E2E2; }
  .btn {
    min-width: 210px; } }


.pd2 {
  background: #F5F5F5;
  padding: 44px 0 60px;
  .has-banner {
    padding-bottom: 130px;
    @include maxtablet {
      padding-bottom: 0;
      .pd2_banner {
        margin-top: 20px;
        position: relative; } } }
  &_nav {
    margin: 0 -6px 42px;
    a {
      line-height: 48px;
      padding: 0 16px;
      background: #D7D7D7;
      border-radius: 4px;
      color: #7e7e7e;
      font-size: 21px;
      display: inline-block;
      font-weight: bold;
      margin-left: 6px;
      margin-right: 6px;
      margin-bottom: 12px;
      // &+ a
      //   margin-left: 12px
      &.active {
        background: $red;
        color: #fff; } } }
  .dropdown.bootstrap-select.nav-select {
    margin: 0 0 24px;
    .bttn {
      background: $red;
      color: #fff;
      font-weight: bold;
      line-height: 48px;
      font-size: 21px; } }
  &_list {
    margin-bottom: 44px; }
  &_banner {
    margin-bottom: 38px;
    position: absolute;
    bottom: 0;
    a {
      display: block; }
    img {
      width: 100%; }
    @include maxtablet {
      width: calc(100% - 16px); } }
  &_item {
    min-height: 32px;
    padding: 4px 12px 20px 50px;
    color: #636362;
    img {
      // position: absolute
      // left: 12px
      // right: 0
      position: absolute;
      left: 12px;
      top: 9px;
      width: 20px;
      height: 20px; } }
  .link {
    color: #636362;
    text-decoration: underline;
    display: none;
    padding: 0 15px;
    &:hover {
      color: $red; } }
  &_img {
    @include imgBg(40% 0);
    box-shadow: 0 20px 60px 0 rgba(0,0,0,0.25); }

  &_content {
    padding-right: 54px; }

  .slider:not(.open) {
    display: none !important; }
  .slider {
    padding-bottom: 30px; }

  // .owl-stage,
  .owl-height {
    transition: none !important; }


  .owl-nav {
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    position: absolute; }
  .owl-prev {
    background: #FFF url("../images/ic-chevron-right.svg") no-repeat center;
    opacity: .5;
    background-size: 50px;
    width: 30px;
    top: -40px;
    left: 0;
    transition: all .5s ease-in-out;
    &:hover {
      opacity: 1; } }
  .owl-next {
    background: #FFF url("../images/ic-chevron-right.svg") no-repeat center;
    opacity: .5;
    background-size: 50px;
    width: 30px;
    top: -40px;
    right: 0;
    transition: all .5s ease-in-out;
    &:hover {
      opacity: 1; } }
  .owl-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    line-height: 4px; }
  .owl-dot {
    height: 4px;  width: 64px;  background: #D8D8D8;
    border-radius: 0;
    &.active {
      background: #B4B4B4; } } }


.pd3 {
  background: $red;
  color: #fff;
  position: relative;
  .tab-content {
    padding-right: 42px; }
  .container {
    padding: 60px 12px 80px; }
  .map {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50% - 12px);
    height: 100%;
    background-color: #eee; }
  &_nav {
    margin: 0 0 24px;
    li {
      display: inline-block; }
    a {
      font-size: 26px;	line-height: 31px;
      padding-bottom: 4px;
      display: block;
      border-bottom: 5px solid #fff;
      min-width: 220px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      &:not(.active) {
        opacity: 0.5;
        border-color: transparent; } } }
  &_item {
    position: relative;
    padding-left: 40px;
    &:not(:last-child) {
      margin: 0 0 32px; }
    img {
      position: absolute;
      left: 0;
      top: 0;
      max-width: 24px;
      max-height: 24px; }
    h3 {
      font-size: 21px;	line-height: 30px;
      margin: 0; }
    ul {
      margin-top: 12px;
      list-style: none;
      padding-left: 0;
      li {
        border-bottom: 1px solid rgba(#e2e2e2,.5);
        padding: 8px 0;
        &:first-child:last-child {
          border: 0; } }
      span {
        display: block; } } } }



.pd4 {
  padding: 60px 0 0;
  position: relative; }
//   &:before,&:after
//     content: ""
//     background-image: linear-gradient(to right, $red 30%, rgba(255, 255, 255, 0) 0%);
//     background-position: top;
//     background-size: 20px 1px;
//     background-repeat: repeat-x;
//     position: absolute
//     top: 0
//     height: 1px
//     left: 0
//     width: 100%
//   &:after
//     bottom: 0
//     top: auto

.pd5 {
  padding-top: 0 !important; }


.page-not-found {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-bottom: 6vw;
  background: linear-gradient(0deg, #F5FDFF 0%, #B3D9E4 100%);
  &_content {
    position: relative;
    z-index: 2;
    text-align: center;
    overflow: hidden;
    padding: 32px;
    h1 {
      font-size: 180px;
      line-height: 180px;
      font-weight: bold;
      margin-bottom: 32px;
      color: $red; }
    p {
      font-size: 18px;
      line-height: 26px;
      font-weight: bold; } }
  &_pattern {
    @include absfull;
    background: url('../images/body-pattern.svg') no-repeat center;
    background-size: contain;
    transform: scale(0.9);
    z-index: 1; }
  &:before {
    content: "";
    position: absolute;
    height: 6vw;
    border-bottom: 0 solid transparent;
    border-left: 80vw solid $red;
    border-right: 50vw solid transparent;
    border-top: 6vw solid transparent;
    bottom: 0;
    left: 0; } }



.ab1 {
  padding-top: 44px;
  padding-bottom: 60px; }

.milestone {
  .title {
    margin-bottom: 62px; }
  &-wrap {
    display: flex;
    position: relative;
    max-width: 1045px;
    margin: 0 auto; }
  &-nav {
    width: 100%;
    max-width: 130px;
    flex: 0 0 130px;
    .nav {
      list-style: none;
      margin: 0;
      padding: 0;
      a {
        display: block;
        padding-bottom: 18px;
        font-size: 15px;
        line-height: 24px;
        color: #636362;
        position: relative;
        padding-left: 22px;
        &:after {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          background-color: #9c9c9c;
          border-radius: 5px;
          position: absolute;
          top: 7px;
          left: 0; }
        &:before {
          content: "";
          display: block;
          height: 100%;
          width: 1px;
          background-color: #636362;
          opacity: 0.2;
          position: absolute;
          top: 7px;
          left: 4.5px; }
        &:last-child:before {
          display: none; }
        &.active {
          font-size: 21px;
          color: $red;
          font-weight: bold;
          padding-bottom: 35px;
          &:after {
            width: 13px;
            height: 13px;
            border-radius: 6.5px;
            left: -1.5px;
            top: 5.5px;
            border: 2px solid $red;
            background-color: #FFF; } } } } }

  &-content {
    position: relative;
    width: 100%;
    max-width: calc(50% - 130px);
    flex: 0 0 calc(50% - 130px);
    padding-right: 48px;
    h3 {
      font-size: 21px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 16px; } }
  &-img {
    position: relative;
    width: 100%;
    max-width: 50%;
    flex: 0 0 50%;
    .img {
      @include imgBg(35% 0);
      margin: -1px 0;
      box-shadow: 0 20px 60px 0 rgba(0,0,0,0.25); } } }


.ab2 {
  padding: 60px 0;
  background-color: #EFF3F7;
  h2 {
    font-size: 36px;
    line-height: 43px;
    color: #212121;
    margin-bottom: 16px; }
  &-content {
    padding-left: 68px; }
  &-chart {
    width: 100%;
    position: relative;
    canvas {
      pointer-events: none; }
    .logo {
      display: block;
      width: 60%;
      height: 60%;
      border-radius: 50%;
      background-color: #eff3f7;
      position: absolute;
      top: 20%;
      left: 20%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 145px; } }
    &-legend {
      column-count: 2;
      margin-bottom: 32px;
      li {
        padding-left: 40px;
        // padding-top: 8px
        margin-bottom: 8px;
        position: relative;
        .icon {
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 4px;
          left: 0;
          background-color: #FFF;
          border-radius: 12px; } } } } }



.ab3 {
  padding: 164px 0;
  position: relative;
  color: #fff;
  text-align: center;
  .container {
    position: relative;
    z-index: 3; }
  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include bgcenter;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.62) 100%); } }
  &:before,&:after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    border-top: 9.375vw solid #f9f9f9;
    //border-left: 50vw solid #fff
    border-right: 100vw solid transparent;
    z-index: 2; }
  &:before {
    border-top-color: #EFF3F7; }
  &:after {
    top: auto;
    bottom: -1px;
    transform: rotate(-180deg); }
  h2 {
    font-size: 48px;
    line-height: 58px;
    color: #fff;
    margin: 0 auto 32px;
    max-width: 900px; }
  small {
    display: block;
    font-size: 32px;
    line-height: 42px;
    font-weight: bold;
    color: #FFF;
    margin: 0 auto;
    max-width: 900px; }

  .btn {
    margin-bottom: 88px; }
  .row {
    justify-content: center; }
  &-item {
    padding: 0 32px;
    h3 {
      font-size: 18px;
      line-height: 26px;
 }      // font-weight: bold
    p {
      font-size: 18px;
      line-height: 26px; } }
  &-icon {
    padding: 20px;
    text-align: center;
    img {
      height: 60px;
      width: auto;
      margin: 0 auto; } }

  &-extra {
    // margin-top: 48px
    // margin-bottom: 24px
    padding: 164px 0 188px;
    &:before {
      border-top-color: #f9f9f9; }
    &:after {
      border-top-color: #f5f5f5; } } }



.ab4 {
  padding: 48px 0 60px;
  &_item {
    @include imgBg(38.46% 0);
    margin: -1px 0; }
  &_slider .owl-stage-outer {
    box-shadow: 0 20px 60px 0 rgba(0,0,0,0.25); } }



.cr1 {
  padding: 44px 0 60px;
  .title {
    margin: 0; } }

.cr2 {
  &_item {
    margin-bottom: 44px;
    font-size: 15px;
    line-height: 24px;
    &-img {
      position: relative;
      width: 100%;
      margin-bottom: 16px;
      .img {
        width: 100%;
        padding-top: 56.122%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; } }
    &-title {
      margin-bottom: 8px;
      font-size: 15px;
      line-height: 24px;
      display: block;
      color: #212121;
      font-weight: bold;
      transition: all .5s;
      // &:hover
 } } }      //// color: $red

.cr3 {
  padding-bottom: 60px;
  .title {
    margin-bottom: 24px; }
  &_container {
    max-width: 808px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 12px;
    padding-left: 12px; } }


.no-result {
  list-style: none;
  display: block;
  width: 100%;
  text-align: center;
  padding: 48px 0;
  font-weight: normal; }

.accordion-careers {
  .card {
    &-header {
      font-size: 15px;
      line-height: 24px;
      &-wrap {
        display: flex;
        position: relative;
        cursor: pointer;
        padding-right: 18px;
        &:after {
          content: "";
          position: absolute;
          display: block;
          right: 0;
          top: 0;
          width: 20px;
          height: 20px;
          background-image: url('../images/ic-chevron-right.svg');
          background-size: 200%;
          background-position: center;
          background-repeat: no-repeat;
          transform: rotate(90deg);
          transition: transform .3s ease; }
        &.collapsed:after {
          transform: rotate(0); }
        &-left {
          width: 100%;
          position: relative;
          flex: 0 0 65%;
          max-width: 65%; }
        &-right {
          width: 100%;
          position: relative;
          flex: 0 0 35%;
          max-width: 35%; } }
      .title {
        font-size: 15px;
        line-height: 24px;
        display: block;
        text-align: left;
        margin: 0; }
      .subcategory {
        display: block;
        color: #636362;
        margin: 8px 0 0; }
      .location {
        display: block;
        color: #636362;
        position: relative;
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 9px;
          height: 12px;
          background-image: url(../images/icon-marker.svg);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          left: -17px;
          top: 6px; } }
      .closes {
        margin: 8px 0 0;
        display: block;
        color: $red; } }
    &-body {
      font-size: 15px;
      line-height: 24px;
      padding: 12px 24px;
      h4 {
        font-weight: bold;
        margin: 0 0 11px; }
      p {
        margin: 0 0 32px; } } } }

.loading {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    width: calc(100% + 32);
    height: calc(100% + 32);
    top: -16px;
    right: -16px;
    bottom: -16px;
    left: -16px;
    border-radius: 4px;
    background: rgba(255,255,255,0.6); }
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 3;
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    background-image: url(../images/loading-search.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; } }

