/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* Fonts */
/* ------------------------------------------------------------------------------------------------ */
@font-face { font-family: "TradeGothic LT"; src: local(TradeGothicLT-Light); src: url("../fonts/TradeGothicLT-Light.eot"); src: url("../fonts/TradeGothicLT-Light.eot#iefix") format("embedded-opentype"), url("../fonts/TradeGothicLT-Light.woff2") format("woff2"), url("../fonts/TradeGothicLT-Light.woff") format("woff"), url("../fonts/TradeGothicLT-Light.ttf") format("truetype"), url("../fonts/TradeGothicLT-Light.svg") format("svg"); font-style: normal; font-weight: 200; font-display: swap; }

@font-face { font-family: "TradeGothic LT"; src: local(TradeGothicLT); src: url("../fonts/TradeGothicLT.eot"); src: url("../fonts/TradeGothicLT.eot#iefix") format("embedded-opentype"), url("../fonts/TradeGothicLT.woff2") format("woff2"), url("../fonts/TradeGothicLT.woff") format("woff"), url("../fonts/TradeGothicLT.ttf") format("truetype"), url("../fonts/TradeGothicLT.svg") format("svg"); font-style: normal; font-weight: 400; font-display: swap; }

@font-face { font-family: "TradeGothic LT"; src: local(TradeGothicLT-Bold); src: url("../fonts/TradeGothicLT-Bold.eot"); src: url("../fonts/TradeGothicLT-Bold.eot#iefix") format("embedded-opentype"), url("../fonts/TradeGothicLT-Bold.woff2") format("woff2"), url("../fonts/TradeGothicLT-Bold.woff") format("woff"), url("../fonts/TradeGothicLT-Bold.ttf") format("truetype"), url("../fonts/TradeGothicLT-Bold.svg") format("svg"); font-style: normal; font-weight: 700; font-display: swap; }

/* ------------------------------------------------------------------------------------------------ */
/* Common */
/* ------------------------------------------------------------------------------------------------ */
*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

/* Common */
hr { border: 0; border-top: 1px solid #e2e2e2; }

/* ------------------------------------------------------------------------------------------------ */
/* Typography */
/* ------------------------------------------------------------------------------------------------ */
* { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-decoration: none; font-feature-settings: "kern" 1; font-kerning: normal; }

*::-webkit-scrollbar { width: 5px; }

*::-webkit-scrollbar-track { background: #fff; border-radius: 10px; }

*::-webkit-scrollbar-thumb { background: rgba(202, 5, 56, 0.8); border-radius: 5px; }

*::-webkit-scrollbar-thumb:window-inactive { background: rgba(51, 51, 51, 0.4); }

p { margin: 0; padding: 0; }

p em, p i { font-style: italic; }

p u { text-decoration: underline; }

h1, h2, h3, h4 { margin: 0 0 10px; line-height: normal; font-weight: 400; }

article p { word-break: break-word; -webkit-hyphens: none; -moz-hyphens: none; -ms-hyphens: none; hyphens: none; clear: both; }

article a:visited { text-decoration: underline; }

article a:hover { text-decoration: none; }

article blockquote { font-style: italic; }

article blockquote:before { content: "\201C"; display: inline-block; padding-right: 0.4em; }

article li ul, article li ol { margin: 0 1.5em; }

article ul, article ol { margin: 0 0 15px; padding-left: 30px; }

article ul:last-child, article ol:last-child { margin: 0; }

article ul { list-style-type: disc; }

article ol { list-style-type: decimal; }

article ol ol { list-style: upper-alpha; }

article ol ol ol { list-style: lower-roman; }

article ol ol ol ol { list-style: lower-alpha; }

article h1, article h2, article h3, article h4, article h5, article h6 { color: #000000 !important; margin: 0 0 15px; font-weight: 500; }

article h1 { font-size: 24px; }

article h2 { font-size: 21px; }

article h3 { font-size: 18px; }

article h4 { font-size: 15px; }

article h5 { font-size: 12px; }

article h6 { font-size: 10px; }

article li { margin-bottom: 10px; }

ul, ol { padding: 0; margin: 0; }

img { max-width: 100%; }

img.aligncenter { display: block; margin: auto; }

img.alignright { float: right; clear: both; margin: 0 0 15px; }

ul { list-style: none; }

label { font-weight: normal; }

html { line-height: 1.15; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; -ms-overflow-style: scrollbar; -webkit-tap-highlight-color: transparent; }

html, body { font-weight: 400; font-family: "TradeGothic LT",sans-serif; font-size: 18px; line-height: 32px; margin: 0; color: #000000; background: #F9F9F9; text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased; }

html input, body input { font-family: inherit; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section { display: block; }

input:focus { outline: none; box-shadow: none; }

main { overflow: hidden; }

button { color: inherit; }

.mb-40 { margin-bottom: 40px; }

.mt-40 { margin-top: 40px; }

.mb-48 { margin-bottom: 48px; }

.mt-48 { margin-top: 48px; }

.mb-56 { margin-bottom: 56px; }

.mt-56 { margin-top: 56px; }

.mb-64 { margin-bottom: 64px; }

.mt-64 { margin-top: 64px; }

.mb-72 { margin-bottom: 72px; }

.mt-72 { margin-top: 72px; }

.mb-80 { margin-bottom: 80px; }

.mt-80 { margin-top: 80px; }

.mb-88 { margin-bottom: 88px; }

.mt-88 { margin-top: 88px; }

.mb-96 { margin-bottom: 96px; }

.mt-96 { margin-top: 96px; }

.mb-104 { margin-bottom: 104px; }

.mt-104 { margin-top: 104px; }

/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------------------------------------------ */
.hiden { display: none; }

input, textarea { padding: 0; border-radius: 0; }

h2 { font-size: 42px; line-height: 51px; font-weight: bold; margin: 0 0 24px; color: #CA0538; }

.btn, body .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] { text-align: center; display: inline-block; padding: 0 24px; font-size: 16px; font-weight: 700; line-height: 48px; vertical-align: top; border-radius: 4px; border: none; color: #fff; background: #CA0538; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }

.btn:hover, body .gform_wrapper .gform_footer input.button:hover, .gform_wrapper .gform_footer input:hover[type=submit], .gform_wrapper .gform_page_footer input.button:hover, .gform_wrapper .gform_page_footer input:hover[type=submit] { background: #98042a; }

.btn:focus, body .gform_wrapper .gform_footer input.button:focus, .gform_wrapper .gform_footer input:focus[type=submit], .gform_wrapper .gform_page_footer input.button:focus, .gform_wrapper .gform_page_footer input:focus[type=submit] { outline: none; }

.btn.big, body .gform_wrapper .gform_footer input.big.button, .gform_wrapper .gform_footer input.big[type=submit], .gform_wrapper .gform_page_footer input.big.button, .gform_wrapper .gform_page_footer input.big[type=submit] { line-height: 55px; }

.btn.disabled, body .gform_wrapper .gform_footer input.disabled.button, .gform_wrapper .gform_footer input.disabled[type=submit], .gform_wrapper .gform_page_footer input.disabled.button, .gform_wrapper .gform_page_footer input.disabled[type=submit] { background: #DDDDDD; pointer-events: none; }

.btn.block, body .gform_wrapper .gform_footer input.block.button, .gform_wrapper .gform_footer input.block[type=submit], .gform_wrapper .gform_page_footer input.block.button, .gform_wrapper .gform_page_footer input.block[type=submit] { width: 100%; }

.btn-gray { background: #D8D8D8; color: #212121; }

.btn-gray:hover { background: #bfbebe; }

.title { margin: 0 auto 44px; text-align: center; }

.title p { max-width: 856px; font-size: 18px; line-height: 32px; display: block; margin-left: auto; margin-right: auto; }

p:not(:last-child), article:not(:last-child) { margin-bottom: 24px; }

.owl-prev, .owl-next { width: 80px; height: 80px; position: absolute; top: calc(50% - 40px); right: -80px; background: url("../images/ic-chevron-right.svg") no-repeat center; background-size: 100% 100%; cursor: pointer; }

.owl-prev { left: -80px; right: 0; transform: scaleX(-1); }

.owl-dots { margin: 12px 0 0; text-align: right; }

.owl-dot { display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: #CA0538; margin: 0 4px; }

.owl-dot:not(.active) { background: #C6C6C6; }

.slider-custom-dot .owl-dots { position: absolute; bottom: -50px; left: 0; width: 100%; margin: 0; text-align: center; line-height: 4px; }

.slider-custom-dot .owl-dot { height: 4px; width: 64px; background: #D8D8D8; border-radius: 0; }

.slider-custom-dot .owl-dot.active { background: #B4B4B4; }

.block { display: block; }

a { color: #CA0538; transition: all .5s; }

a.full-link { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 2; }

a.line { text-decoration: underline; }

a.with-arrow { padding-left: 40px; position: relative; display: inline-block; font-weight: bold; }

a.with-arrow:before { content: ""; position: absolute; left: 0; top: 0; bottom: 0; background: url("../images/red-long-arrow.svg") no-repeat center; background-size: 100% auto; width: 29px; transition: all .5s; }

a.with-arrow.white { color: #fff; }

a.with-arrow.white:before { background-image: url("../images/white-long-arrow.svg"); }

a.with-arrow:hover { text-decoration: underline; }

a.with-arrow:hover:before { left: 8px; }

.fright { float: right; }

.text-right { text-align: right; }

.slider:not(.owl-loaded) > *:not(:first-child) { display: none; }

*:focus { outline: none; }

.text-center { text-align: center; }

body.modal-open { overflow: hidden; }

.modal { position: fixed; z-index: 11; top: 0; left: 0; width: 101%; height: 100vh; overflow: auto; display: none; transition: all .5s; }

.modal:not(.show) { opacity: 0; }

.modal:not(.show) .modal-dialog { transform: translate(0, -30px); }

.modal .modal-dialog { max-width: 540px; padding: 32px 24px; background: #fff; margin: 10vh auto; transition: all .5s ease-out; position: relative; border: 1px solid #E2E2E2; }

.modal .modal-dialog h3 { text-align: center; }

.modal .close-btn { position: absolute; top: 16px; right: 16px; width: 24px; display: block; opacity: .3; transition: all .3s; cursor: pointer; }

.modal .close-btn:hover { opacity: .7; }

.modal .form-group { text-align: left; }

.modal a.back { margin: 0 0 40px; }

.modal h3 { color: #CA0538; font-weight: bold; margin: 0 0 12px; font-size: 26px; line-height: 31px; }

.modal p { margin: 0 0 24px !important; }

.modal.purple .modal-dialog { background: #5315FF; color: #fff; }

.modal.purple a.back { margin: 0 0 28px; }

.modal .range_slider { margin-bottom: 30px; }

.modal .ctrip_range { margin-top: 0; }

.modal-bottom { top: auto; bottom: 0; background: rgba(255, 255, 255, 0.8); }

.modal-bottom:not(.show) .modal-dialog { transform: translate(0, 50%); }

.modal-bottom h2 { display: inline-block; margin: 0 0 30px; font-size: 22px; letter-spacing: -0.07px; line-height: 27px; }

.modal-bottom .modal-dialog { position: fixed; bottom: 0; left: 0; right: 0; min-height: 0; box-shadow: 0 6px 14px 5px rgba(0, 0, 0, 0.5); }

.modal-backdrop { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255, 255, 255, 0.7); z-index: 10; }

.slider:not(.owl-carousel) > *:not(:first-child) { display: none; }

.tab-pane ul { list-style: disc; padding-left: 25px; }

.tab-pane:not(.active) { display: none; }

.list-checkmark { list-style: none; padding: 0; }

.list-checkmark > li { padding-left: 30px; margin-bottom: 8px; position: relative; }

.list-checkmark > li:last-child { margin-bottom: 0; }

.list-checkmark > li:before { content: ""; display: block; width: 16px; height: 16px; background: url("../images/ic-checkmark.svg") no-repeat center; background-size: contain; position: absolute; left: 0; top: calc(50% - 8px); }

.alert { padding: 10px; border-radius: 2px; background: #f9f9f9; border: 1px solid #e2e2e2; padding: .75rem 1.25rem; margin-bottom: 1rem; }

.alert-center { text-align: center; }

.alert-danger { color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; }

.alert-success { color: #155724; background-color: #d4edda; border-color: #c3e6cb; }

.fade { transition: opacity 0.15s linear; }

.fade:not(.in):not(.show) { display: none; }

.collapse:not(.show) { display: none; }

.collapsing { position: relative; height: 0; overflow: hidden; transition: height 0.35s ease; }

@media (prefers-reduced-motion: reduce) { .collapsing { transition: none; } }

.accordion_title { position: relative; font-weight: bold; margin: 0; cursor: pointer; padding-right: 26px; }

.accordion_title:after { content: ""; position: absolute; display: block; right: 0; top: 0; width: 20px; height: 20px; background-image: url("../images/ic-chevron-right.svg"); background-size: 200%; background-position: center; background-repeat: no-repeat; transform: rotate(90deg); transition: transform .3s ease; }

.accordion_title.collapsed:after { transform: rotate(0); }

.accordion .card { border-bottom: 1px solid #E2E2E2; }

.accordion .card-header { padding: 12px 0; }

.accordion .card-body { padding: 4px 0 32px; }

.sidebar { background: #EFF3F7; border-radius: 6px; padding: 48px 24px; }

.side-menu-toggle .item { cursor: pointer; }

.side-menu-toggle .item:not(.active):hover { opacity: .5; }

.side-menu .item { display: block; padding: 24px 0; border-bottom: 1px solid #CDD2D7; opacity: 1; transition: all .3s ease; color: #212121; }

.side-menu .item:last-child { border-bottom-color: transparent; }

.side-menu .item_title { display: block; font-size: 18px; line-height: 32px; margin-bottom: 8px; font-weight: bold; }

.side-menu .item.active .item_title { color: #CA0538; }

.dropdown.bootstrap-select.nav-select .bttn { background: #fff; }

.ums_map_opts, .umsMapDetailsContainer, .ums_MapPreview { position: absolute; height: 100% !important; width: 100%; }

.leaflet-popup-close-button { display: none !important; }

.umsMarkerTitle { display: none !important; }

.infobox-info img, .umsMarkerDesc img { min-width: 160px; }

.leaflet-popup-content p { margin: 0 0 8px !important; }

.leaflet-popup-content-wrapper { border-radius: 0 !important; }

.leaflet-marker-pane img { width: 30px !important; left: -15px !important; }

.umsMarkerDesc { text-align: center !important; }

.form-search { position: relative; width: 100%; margin-bottom: 24px; }

.form-search-group { position: relative; width: 100%; display: flex; justify-content: center; }

.form-search input { height: 42px; width: 100%; border: 1px solid #E2E2E2; border-radius: 4px; background-color: #FFFFFF; padding: 10px; font-size: 15px; line-height: 24px; display: block; margin-right: 8px; }

.form-search button { background-color: #636362; border: 1px solid #636362; border-radius: 4px; padding: 0 25px; font-size: 15px; line-height: 40px; color: #FFF; }

.disabled { pointer-events: none; }

/* ------------------------------------------------------------------------------------------------ */
/* Header */
/* ------------------------------------------------------------------------------------------------ */
.header { position: fixed; left: 0; width: 100%; top: 48px; z-index: 10; min-height: 80px; color: #fff; transition: all .3s; }

.header .container { position: relative; background: #bc4269; text-align: center; border-radius: 4px; box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15); transition: all .3s; }

.header_logo { max-width: 145px; display: inline-block; margin: 12px 0; }

.header_left-menu, .header_right-menu { position: absolute; width: 40%; top: 0; bottom: 0; padding: 36px 0 0; }

.header_left-menu { left: 0; text-align: left; padding-left: 2.61%; }

.header_right-menu { right: 0; text-align: right; padding-right: 2.61%; padding-top: 30px; }

.header .header_top-menu { position: absolute; right: 0; top: -35px; }

.header .header_top-menu .with-child > a, .header .header_top-menu .has-sub > a { color: #636362; }

.header .header_top-menu .dropdown.bootstrap-select .bttn { color: #636362; }

.header .header_top-menu .menu-lang { display: none; }

.header li { display: inline-block; vertical-align: middle; text-align: left; position: relative; }

.header li + li { margin-left: 8%; }

.header li select { opacity: 0; }

.header li .dropdown.bootstrap-select { margin-bottom: 10px; }

.header li .dropdown.bootstrap-select .bttn { border: 0; font-size: 16px; line-height: 24px; font-weight: bold; text-transform: uppercase; }

.header li .dropdown.bootstrap-select .bttn:after { content: "\f0d7"; top: 4px; line-height: 24px; font-size: 12px; }

.header li .dropdown.bootstrap-select div.dropdown-menu { background: #293037; border-radius: 4px; border: 0; top: 13px !important; overflow: visible !important; }

.header li .dropdown.bootstrap-select div.dropdown-menu:before { content: ""; position: absolute; border-style: solid; height: 0; width: 0; border-color: transparent transparent #293037; border-width: 0 6px 6px; top: -6px; left: 12px; }

.header li .dropdown.bootstrap-select div.dropdown-menu li { display: block; margin: 0; }

.header li .dropdown.bootstrap-select div.dropdown-menu li a { border: 0; color: #fff; font-size: 16px; line-height: 24px; font-weight: bold; text-transform: uppercase; display: block; }

.header li .dropdown.bootstrap-select div.dropdown-menu li.selected a { background: transparent; }

.header li.with-child > a, .header li.has-sub > a { pointer-events: none; }

.header li.with-child > a:after, .header li.has-sub > a:after { content: "\f0d7"; font-family: fontAwesome; width: 20px; text-align: center; display: inline-block; font-size: 12px; position: relative; top: -2px; }

.header li.with-child.sub-open, .header li.has-sub.sub-open { z-index: 2; }

.header li.with-child.sub-open .header_submenu, .header li.with-child.sub-open > .sub-menu, .header li.has-sub.sub-open .header_submenu, .header li.has-sub.sub-open > .sub-menu { display: block; }

@media (min-width: 1200px) { .header li.with-child:hover > .header_submenu, .header li.with-child:hover > .sub-menu, .header li.has-sub:hover > .header_submenu, .header li.has-sub:hover > .sub-menu { display: block; } }

.header li.header_enquiry span { font-size: 11px; line-height: 14px; font-weight: bold; text-transform: uppercase; display: block; }

.header li a { display: block; color: #fff; font-size: 16px; line-height: 24px; font-weight: bold; text-transform: uppercase; padding: 0 0 6px; border-bottom: 2px solid transparent; }

.header li a:hover { opacity: 0.5; }

.header li.active > a, .header li.current-menu-item > a { border-color: #fff; }

.header_submenu, .header .sub-menu { position: absolute; min-width: 200px; text-align: left; top: 90%; display: none; margin-top: 8px; background: #293037; border-radius: 4px; }

.header_submenu:after, .header .sub-menu:after { content: ""; display: block; width: 100%; height: 8px; top: -8px; position: absolute; left: 0; }

.header_submenu:before, .header .sub-menu:before { content: ""; position: absolute; border-style: solid; height: 0; width: 0; border-color: transparent transparent #293037; border-width: 0 6px 6px; top: -6px; left: 12px; }

.header_submenu.short, .header .sub-menu.short { min-width: 0; }

.header_submenu li, .header .sub-menu li { padding: 10px 12px; margin: 0; display: block; }

.header_submenu li + li, .header .sub-menu li + li { margin-left: 0; }

.header_submenu a, .header .sub-menu a { padding: 0; border: none; }

.header_submenu .has-sub2 > a:after, .header .sub-menu .has-sub2 > a:after { content: "\f0da"; font-family: fontAwesome; width: 20px; text-align: center; display: inline-block; font-size: 12px; position: relative; top: -2px; float: right; }

@media (min-width: 1200px) { .header_submenu .has-sub2:hover > .sub-menu, .header_submenu .has-sub2.sub-open > .sub-menu, .header .sub-menu .has-sub2:hover > .sub-menu, .header .sub-menu .has-sub2.sub-open > .sub-menu { display: block; } }

.header_submenu .sub-menu, .header .sub-menu .sub-menu { display: none; top: 0; left: 100%; margin: 0 0 0 8px; }

.header_submenu .sub-menu:after, .header .sub-menu .sub-menu:after { width: 8px; height: 100%; left: -8px; top: 0; }

.header_submenu .sub-menu:before, .header .sub-menu .sub-menu:before { display: none; }

.header.sticky { top: 0; background: #bc4269; box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15); }

.header.sticky .header_logo { max-width: 100px; }

.header.sticky .header_left-menu { padding-top: 26px; }

.header.sticky .header_right-menu { padding-top: 20px; }

.header.sticky .container { background: transparent; border-radius: 0; box-shadow: none; }

.burger-menu { position: absolute; right: 12px; top: 22px; cursor: pointer; width: 32px; height: 32px; z-index: 2; border-radius: 2px; cursor: pointer; display: none; }

.burger-menu span { position: absolute; top: 4px; right: 4px; width: 24px; height: 3px; background: #fff; transition: transform .5s, top .5s .5s; }

.burger-menu span:nth-child(2) { width: 13px; top: 12px; }

.burger-menu span:nth-child(3) { top: 19px; width: 19px; }

body.menu-open { overflow: hidden; }

body.menu-open header .header_logo { right: 154px; }

body.menu-open header .header_logo svg path { fill: #fff; }

body.menu-open .header_nav { opacity: 1; visibility: visible; }

body.menu-open nav { opacity: 1; transform: none; }

body.menu-open .burger-menu span { transition: top .5s, transform .5s .5s; }

body.menu-open .burger-menu span:nth-child(1) { top: 15px; transform: rotate(45deg); }

body.menu-open .burger-menu span:nth-child(3) { width: 24px; top: 15px; transform: rotate(-45deg); }

body.menu-open .burger-menu span:nth-child(2) { opacity: 0; }

/* ------------------------------------------------------------------------------------------------ */
/* Form */
/* ------------------------------------------------------------------------------------------------ */
label { display: block; }

.form-group { margin: 0 0 16px; color: #485460; }

.form-group label:not(.error) { color: #636362; font-weight: 400; margin: 0; -webkit-appearance: none; font-size: 18px; line-height: 26px; }

.form-group label:not(.error) span { font-weight: 400; }

.form-group a.right { float: right; color: #485460; font-weight: 400; }

.form-group a.right:hover { text-decoration: underline; }

.error { font-weight: 400; padding: 4px 0; text-align: left; margin: -10px 0 15px; color: #CA0538; }

.error:before { content: "\f06a"; font-family: fontAwesome; margin-right: 4px; }

form small { display: block; font-size: 14px; line-height: 20px; padding: 4px 0 0; }

form small.info { font-size: 12px; }

.field, .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) { display: block; width: 100%; height: 36px; border: none; border-bottom: 1px solid rgba(216, 216, 216, 0.5); background: transparent; padding: 8px 0; font-size: 15px; line-height: 24px; -webkit-appearance: none; }

.help-text { opacity: 0.6; }

textarea.field { height: auto; }

textarea.field.text { height: 201px; border: 1px solid #E8E8E8; border-radius: 4px; margin: 10px 0 0; padding: 8px 12px; }

.dropdown.bootstrap-select.field, .gform_wrapper input.dropdown.bootstrap-select:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) { padding: 0; }

.dropdown.bootstrap-select.field .bttn, .gform_wrapper input.dropdown.bootstrap-select:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) .bttn { border: 0; padding-left: 0; line-height: 40px; }

.dropdown.bootstrap-select.field div.dropdown-menu.show, .gform_wrapper input.dropdown.bootstrap-select:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) div.dropdown-menu.show { border-radius: 0; border: 1px solid rgba(216, 216, 216, 0.5); }

.pass-input { position: relative; }

.eye-password { position: absolute; width: 20px; height: 16px; background: url("../images/icon-eye-hide.svg") no-repeat center; background-size: 100% auto; right: 13px; top: 13px; cursor: pointer; opacity: 1; background-image: url("../images/icon-eye-show.svg"); opacity: 1; }

.field-group { position: relative; }

.field-group .field, .field-group .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper .field-group input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) { padding-right: 120px; }

.field-group .dropdown.bootstrap-select { position: absolute; top: 0; right: 0; border-left: 1px solid #e2e2e2; }

.field-group .dropdown.bootstrap-select .bttn { border: none; width: 110px; }

.date { position: relative; }

.date:before { content: ""; position: absolute; width: 20px; right: 13px; top: 10px; background: url("../images/ic-calendar-small.svg") no-repeat center; height: 20px; }

.date .field, .date .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper .date input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) { padding-right: 46px; }

.radio { margin-top: 4px; position: relative; display: inline-block; }

.radio label { margin: 0; cursor: pointer; }

.radio div { padding-left: 28px; }

.radio div:before, .radio div:after { content: ""; position: absolute; top: 0; left: 0; width: 20px; height: 20px; border-radius: 50%; border: 1px solid #e2e2e2; background: #fff; }

.radio div:after { width: 8px; height: 8px; top: 6px; left: 6px; border: none; }

.radio input { position: absolute; top: 0; width: 0; visibility: hidden; }

.radio input:checked + div:before { background: #1565c0; }

.radio + .radio { margin-left: 24px; }

.checkbox { position: relative; padding: 8px 0; }

.checkbox label { margin: 0; cursor: pointer; font-weight: 400; position: relative; }

.checkbox div label { padding-left: 28px; }

.checkbox div label:before, .checkbox div label:after { content: ""; position: absolute; top: 4px; left: 0; width: 17px; height: 17px; border-radius: 4px; border: 1px solid #E2E2E2; background: #fff; }

.checkbox div label:after { content: "\f00c"; font-family: fontAwesome; color: #fff; text-align: center; font-size: 10px; line-height: 17px; border: none; background: none; }

.checkbox input { position: absolute; top: 0; width: 0; visibility: hidden; }

.checkbox input:checked + div label:before { background: #CA0538; border-color: #b10431; }

.dropdown.bootstrap-select { position: relative; }

.dropdown.bootstrap-select select { position: absolute; bottom: 0; left: 50%; display: block; width: 0.5px; height: 100%; padding: 0; opacity: 0; border: none; visibility: hidden; }

.dropdown.bootstrap-select .bttn { cursor: pointer; background: none; border: 1px solid #E2E2E2; border-radius: 4px; font-size: 14px; font-weight: 500; letter-spacing: -0.04px; line-height: 42px; width: 100%; text-align: left; padding: 0 32px 0 12px; outline: none !important; }

.dropdown.bootstrap-select .bttn:after { content: "\f107"; font-family: fontAwesome; line-height: 31px; top: calc(50% - 15px); right: 10px; position: absolute; }

.dropdown.bootstrap-select div.dropdown-menu { position: absolute; top: 100%; left: 0; z-index: 1000; display: none; text-align: left; background-color: #fff; background-clip: padding-box; border: 1px solid #AFAFAF; border-radius: 4px; margin: 2px 0 0; width: 100%; min-width: 100px; box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.25); }

.dropdown.bootstrap-select div.dropdown-menu a { display: block; padding: 10px 12px; color: #485460; font-weight: 400; cursor: pointer; font-size: 14px; line-height: 20px; }

.dropdown.bootstrap-select div.dropdown-menu li { position: relative; }

.dropdown.bootstrap-select div.dropdown-menu li .fa { display: none; }

.dropdown.bootstrap-select div.dropdown-menu li:hover a { background: rgba(0, 0, 0, 0.05); }

.dropdown.bootstrap-select div.dropdown-menu li.selected a, .dropdown.bootstrap-select div.dropdown-menu li.active a { background: #F9F9F9; }

.dropdown.bootstrap-select div.dropdown-menu.show { display: block; }

.dropdown.bootstrap-select.show .bttn { border-color: #1565BE; }

.bs-searchbox { padding: 10px; background: #eee; }

.bs-searchbox .form-control { width: 100%; height: 40px; border: 1px solid #000; padding: 6px 12px; }

li.no-results { padding: 10px; }

.form-action { margin-top: 24px; }

.form-action .btn, .form-action body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .form-action input.button, .form-action .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_footer .form-action input[type=submit], .form-action .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer .form-action input.button, .form-action .gform_wrapper .gform_page_footer input[type=submit], .gform_wrapper .gform_page_footer .form-action input[type=submit] { min-width: 184px; cursor: pointer; }

label.file_label { display: inline-block; position: relative; margin-right: 12px; }

.file { position: relative; }

.file input { position: absolute; top: 0; left: 0; width: 0; visibility: hidden; }

.file div { line-height: 40px; border-radius: 2px; border: 1px solid #e2e2e2; font-size: 16px; font-weight: bold; text-align: center; padding: 0 30px; display: inline-block; cursor: pointer; margin: 8px 0; transition: all .3s; display: inline-block; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; word-wrap: normal; }

.file div:hover { background: #e2e2e2; }

.file.has-file div { padding: 0 15px; font-weight: normal; }

.field-row { border: 1px solid #e2e2e2; border-radius: 4px; }

.field-row .field, .field-row .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper .field-row input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) { border: none; }

.field-row .dropdown.bootstrap-select .bttn { border: none; }

.field-row [class*="col-4"]:not(:last-child) { border-right: 1px solid #e2e2e2; }

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio { display: flex; flex-wrap: wrap; }

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li { display: block; width: 100%; max-width: 50%; flex: 0 0 50%; }

.gform_wrapper.gf_browser_chrome .gfield_checkbox li input, .gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox], .gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio] { position: absolute !important; opacity: 0 !important; visibility: hidden !important; }

body .gform_wrapper .gfield_checkbox li input, body .gform_wrapper .gfield_checkbox li input[type=checkbox], body .gform_wrapper .gfield_radio li input[type=radio] { position: absolute !important; opacity: 0 !important; visibility: hidden !important; }

body .gform_wrapper .gfield_checkbox li label, body .gform_wrapper .gfield_radio li label { font-size: 18px; line-height: 26px !important; padding-left: 28px; }

body .gform_wrapper .gfield_checkbox li label:before, body .gform_wrapper .gfield_checkbox li label:after, body .gform_wrapper .gfield_radio li label:before, body .gform_wrapper .gfield_radio li label:after { content: ""; position: absolute; top: 4px; left: 0; width: 17px; height: 17px; border-radius: 4px; border: 1px solid #E2E2E2; background: #fff; }

body .gform_wrapper .gfield_checkbox li label:after, body .gform_wrapper .gfield_radio li label:after { content: "\f00c"; font-family: fontAwesome; color: #fff; text-align: center; font-size: 10px; line-height: 17px; border: none; background: none; }

body .gchoice_select_all, body .gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked + label, body .gform_wrapper ul.gfield_radio li input[type=radio]:checked + label { font-weight: normal; }

body .gchoice_select_all:before, body .gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked + label:before, body .gform_wrapper ul.gfield_radio li input[type=radio]:checked + label:before { background: #CA0538; border-color: #b10431; }

.gform_wrapper .top_label .gfield_label, .gform_wrapper legend.gfield_label { color: #636362; font-weight: 400; margin: 0; -webkit-appearance: none; font-size: 18px; line-height: 26px !important; display: block !important; }

.gform_wrapper label.gfield_label, .gform_wrapper legend.gfield_label { font-weight: 400; }

.gform_wrapper .dropdown.bootstrap-select { border-bottom: 1px solid rgba(216, 216, 216, 0.5); }

.gform_wrapper .dropdown.bootstrap-select .bttn { border: 0; padding-left: 0; font-size: 18px; }

.gform_wrapper .dropdown.bootstrap-select div.dropdown-menu { border-radius: 0; border: 1px solid rgba(216, 216, 216, 0.5); }

.gform_wrapper textarea.medium { height: 201px; border: 1px solid #E8E8E8; border-radius: 4px; margin: 10px 0 0; padding: 8px 12px; background-color: transparent; }

.gform_wrapper .gform_footer { padding: 0 !important; margin-top: 32px !important; text-align: right; }

body .gform_wrapper .top_label div.ginput_container { margin: 0 !important; }

body .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] { width: 100%; display: block; margin: 0; min-width: 184px; line-height: 48px !important; font-family: "TradeGothic LT", sans-serif; text-transform: uppercase; }

.gform_confirmation_message { padding: 10px; border-radius: 2px; background: #f9f9f9; border: 1px solid #e2e2e2; padding: .75rem 1.25rem; margin-bottom: 1rem; text-align: center; color: #155724; background-color: #d4edda; border-color: #c3e6cb; font-size: 16px; line-height: 24px; }

.gform_confirmation_message .danger { color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; }

.ginput_container_fileupload .validation_message { display: none; }

body .gform_wrapper .gfield_description { display: block; font-size: 14px; line-height: 20px; padding: 4px 0 0 !important; opacity: .6; }

body .gform_wrapper div.validation_error { display: none; }

body .gform_wrapper li.gfield.gfield_error, body .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning { background-color: transparent; margin-bottom: 6px !important; border: 0; padding: 0; }

body .gform_wrapper .validation_message { font-weight: normal; color: #d63031; opacity: 1; position: relative; }

body .gform_wrapper .validation_message:before { content: "\f06a"; font-family: "fontAwesome"; display: inline-block; margin-right: 6px; }

body .gform_wrapper .gfield_error .gfield_label, body .gform_wrapper li.gfield_error div.ginput_complex.ginput_container label, body .gform_wrapper li.gfield_error ul.gfield_checkbox, body .gform_wrapper li.gfield_error ul.gfield_radio { color: #636362; }

body .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) { border-color: transparent; border-bottom-color: rgba(216, 216, 216, 0.5); }

body .gform_wrapper li.gfield_error textarea { border-color: rgba(216, 216, 216, 0.5); }

/* ------------------------------------------------------------------------------------------------ */
/* Content */
/* ------------------------------------------------------------------------------------------------ */
.hero { max-height: 85vh; min-height: 80vh; padding-top: 240px; background: linear-gradient(0deg, #e4faff 0%, #B3D9E4 100%); position: relative; padding-bottom: 13vw; }

.hero:before { content: ""; position: absolute; bottom: -1px; height: 4.167vw; border-bottom: 4.167vw solid #f9f9f9; border-left: 50vw solid transparent; border-right: 50vw solid #CA0538; border-top: 4.167vw solid transparent; left: 0; right: 0; z-index: 2; }

.hero_pattern { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: url("../images/hero-pattern.svg") no-repeat center; background-size: cover; }

.hero_img { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: no-repeat center; background-size: cover; }

.hero_img { left: auto; width: 47%; bottom: 3vw; background-size: 100% auto; background-position: center bottom; }

.hero .container { position: relative; }

.hero article { max-width: 600px; }

.hero h1 { font-size: 48px; line-height: 58px; font-weight: bold; margin: 0 0 16px; }

.hero p { font-size: 21px; line-height: 30px; }

.hero_slider { position: relative; padding-top: 168px; padding-bottom: 10vw; min-height: auto; max-height: initial; z-index: 1; overflow: hidden; }

.hero_slider:before { border-bottom: 5.167vw solid #f9f9f9; border-left: 60vw solid transparent; border-right: 40vw solid #CA0538; border-top: 3.167vw solid transparent; z-index: 5; }

.hero_slider .slider-custom-dot .owl-dots { bottom: -30px; }

.hero_slider .hero_pattern { z-index: 1; }

.hero_slider .container { padding: 0; }

.hero_slider .slider { position: relative; z-index: 2; }

.hero_slider .hero_item { position: relative; box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.25); z-index: 3; }

.hero_slider .hero_item .bg_img { background-size: cover; background-position: center; background-color: #EFF3F7; position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 1; }

.hero_slider .hero_item .content { z-index: 3; position: relative; min-height: 480px; max-width: 37%; top: 0; left: 0; padding: 96px 0 90px 70px; }

.hero_slider .hero_item .content * { position: relative; z-index: 2; }

.hero_slider .hero_item .content:before { content: ""; display: block; position: absolute; width: calc(100% + 120px); height: 100%; top: 0; left: 0; background: linear-gradient(90deg, rgba(255, 255, 255, 0.75) 10%, rgba(255, 255, 255, 0) 100%); z-index: 1; }

.hero_slider.overlay .hero_item:after { content: ""; position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; background: rgba(0, 0, 0, 0.4); z-index: 1; }

.hero_slider.overlay .content { color: #FFF; display: flex; align-items: center; }

.hero_detail { min-height: 950px; max-height: none; }

.hero_detail .container { position: relative; z-index: 2; }

.hero_detail .container > .row { margin-bottom: -8vw; height: 100%; }

.hero_detail h1 { margin: 0 0 40px; }

.hero_detail h3 { font-size: 26px; line-height: 31px; margin: 0 0 16px; font-weight: bold; }

.hero_detail p { font-size: 18px; line-height: 32px; }

.hero_detail_img { box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.25); background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; }

.hero_detail_img:before { content: ""; display: block; padding: 53.57% 0; }

.hero_detail .col1 > .row { height: 100%; }

.hero_detail .img2 { position: relative; top: 32px; height: calc(100% - 64px); display: flex; }

.hero_detail .img2:before { padding: calc(71.43% - 32px) 0; }

.hero.hero-about .hero_pattern { background: url("../images/about-pattern.svg") no-repeat center; }

.hero-about { min-height: auto !important; max-height: inherit !important; padding-bottom: 400px; padding-top: 200px; }

.hero-about:before { border-bottom: 5.167vw solid #f9f9f9; border-left: 60vw solid transparent; border-right: 40vw solid #CA0538; border-top: 3.167vw solid transparent; }

.hero-about .hero_img { width: 93%; }

.hero-about .hero_img { bottom: 0.5vw; }

.hero-about h1 { color: #CA0538 !important; }

.hero-about p { font-size: 18px; line-height: 32px; }

.hero-about article { margin-left: auto; }

.hero-about .sub { color: #636362; font-size: 21px; line-height: 30px; display: block; margin: 0; font-weight: bold; }

.hs1 { padding: 44px 0; }

.hs1 .title { margin-bottom: 22px; }

.hs1_item { background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; position: relative; color: #fff; }

.hs1_item:before { content: ""; display: block; padding: 72.92% 0; }

.hs1_item:hover .hs1_bg { margin: -5%; width: 110%; height: 110%; }

.hs1_item:hover .hs1_bg:before { opacity: 1; }

.hs1_item:hover .hs1_desc { opacity: 1; max-height: 400px; }

.hs1_item:hover .hs1_text:after { opacity: 1; right: 0; }

.hs1_bg { background-size: cover; background-position: center; background-repeat: no-repeat; background-color: #434343; position: absolute; top: 0; left: 0; right: 0; bottom: 0; transition: all .3s linear; width: 100%; height: 100%; }

.hs1_bg:before { content: ""; position: absolute; bottom: 0; left: 0; right: 0; height: 61.42%; width: 100%; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); opacity: 1; }

.hs1_nation { display: none; }

.hs1_desc { opacity: 0; transition: all .5s; max-height: 0; }

.hs1_text { position: absolute; bottom: 10px; left: 10px; right: 10px; transition: all .5s; padding-right: 20px; font-size: 16px; line-height: 24px; }

.hs1_text:after { content: ""; position: absolute; right: 25px; opacity: 0; bottom: 4px; height: 13px; width: 29px; background: url("../images/white-long-arrow.svg") no-repeat center; transition: all .5s; }

.hs1_name { font-size: 24px; font-weight: bold; line-height: 36px; }

.hs1 .slider { justify-content: center; }

.hs1 .slider .item { padding-left: 15px; padding-right: 15px; display: block !important; }

.hs1 .slider:not(.owl-loaded) .item { max-width: 25%; flex: 0 0 25%; }

.hs1 .slider:not(.owl-loaded) .item:nth-child(4) { display: none !important; }

.hs1 .slider.no-slider { padding-top: 22px; }

.hs1 .slider .owl-prev { left: -50px; }

.hs1 .slider .owl-next { right: -50px; }

.hs1 .owl-stage-outer { padding: 22px 0 30px; }

.hs2 { padding: 24px 0 48px; position: relative; }

.hs2_pattern { position: absolute; top: -30.147%; left: 0; right: 0; height: 100%; background: url("../images/body-pattern.svg") no-repeat left top; }

.hs2 .container { position: relative; }

.hs2 h3 { font-size: 21px; line-height: 30px; font-weight: bold; }

.hs2_title { margin: 0 0 44px; }

.hs2_box { background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; position: relative; margin-bottom: 0; transition: margin .3s ease; }

.hs2_box:before { content: ""; display: block; padding: 20.42% 0; }

.hs2_box.open { margin-bottom: 88px; }

.hs2_box.open .hs2_bg { opacity: 0; }

.hs2_box.open .hs2_col + .hs2_col { border: 0; }

.hs2_bg, .hs2_cat, .hs2_content, .hs2_itemtext, .hs2_slider { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }

.hs2_bg { background-size: cover; background-position: center; background-repeat: no-repeat; background-color: #434343; }

.hs2_bg:before { content: ""; position: absolute; bottom: 0; left: 0; right: 0; height: 61.42%; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); }

.hs2_cat { color: #fff; }

.hs2_cat .row { height: 100%; }

.hs2_col { height: 100%; cursor: pointer; transition: all .5s; }

.hs2_col:nth-child(6):after { transition-delay: 0.8s; }

.hs2_col:nth-child(5):after { transition-delay: 0.9s; }

.hs2_col:nth-child(4):after { transition-delay: 1s; }

.hs2_col:nth-child(3):after { transition-delay: 1.1s; }

.hs2_col:nth-child(2):after { transition-delay: 1.2s; }

.hs2_col:nth-child(1):after { transition-delay: 1.3s; }

.hs2_col:before { content: ""; background: transparent; transition: all .5s; position: absolute; top: 0; left: 0; right: 0; bottom: 0; }

.hs2_col:after { content: ""; background: #f9f9f9; transition: all .5s; opacity: 0; position: absolute; top: 0; left: 0; right: 0; bottom: 0; right: -1px; transition: all .5s; }

.hs2_col:hover:before { background: rgba(0, 0, 0, 0.2); }

.hs2_col + .hs2_col { border-left: 1px solid rgba(255, 255, 255, 0.5); }

.hs2_coltext { position: absolute; bottom: 32px; left: 24px; right: 24px; }

.hs2_content { background: transparent; margin: 0 -12px; color: #fff; }

.hs2_content .owl-dots { margin: 24px 0 36px; text-align: center; line-height: 4px; }

.hs2_content .owl-dot { height: 4px; width: 64px; background: #D8D8D8; border-radius: 0; }

.hs2_content .owl-dot.active { background: #B4B4B4; }

.hs2_slider, .hs2_content { opacity: 0; visibility: hidden; transition: all .5s; }

.hs2_slider { transition: all 1s; }

.hs2_slider:not(.owl-loaded) { display: flex; position: relative; }

.hs2_slider:not(.owl-loaded) .hs2_item { max-width: calc(33.33% - 24px); flex: 0 0 calc(33.33% - 24px); display: block !important; }

.hs2_item { background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; margin: 0 12px; position: relative; transform: translateX(30px); transform-origin: 0 0; opacity: 0; visibility: hidden; transition: all 1s; font-size: 16px; line-height: 24px; }

.hs2_item:before { content: ""; display: block; padding: 63.775% 0; }

.hs2_itemtext { padding: 24px; top: auto; background: linear-gradient(0deg, black 10%, rgba(255, 255, 255, 0) 100%); }

.hs2_item h3 { margin: 0; }

.hs2_item p { max-width: 280px; max-height: 0; opacity: 0; transition: all .5s ease; }

.hs2_item:hover p { max-height: 100%; opacity: 1; }

.hs2 .not-found { width: calc(100% - 12px); margin: 0 12px; padding: 18.8%; background: #f2f2f9; color: #636362; display: flex; align-items: center; justify-content: center; font-size: 24px; line-height: 36px; position: absolute; top: 0; left: 0; }

.hs2_nav { position: absolute; z-index: 2; bottom: -114px; left: 0; right: 0; border-radius: 4px; transition: all .5s; opacity: 0; transform: translateY(30px); visibility: hidden; }

.hs2_nav a { line-height: 50px; background: #D8D8D8; font-size: 14px; line-height: 20px; color: #636362; text-align: left; border: 1px solid #BEBEBE; padding: 8px 36px 8px 12px !important; display: flex; align-items: center; }

.hs2_nav a:hover { background: #CDCDCD; }

.hs2_nav a .svg { position: absolute; top: calc(50% - 10px); right: 12px; }

.hs2_nav a:not(.active) .svg path { fill: #777; }

.hs2_nav a.active { background: #CA0538; color: #fff; }

.hs2_nav a.active .svg { transform: rotate(45deg); }

.hs2_box.open .hs2_col:after { opacity: 1; }

.hs2_box.open .hs2_col:nth-child(1):after { transition-delay: 0s; }

.hs2_box.open .hs2_col:nth-child(2):after { transition-delay: 0.1s; }

.hs2_box.open .hs2_col:nth-child(3):after { transition-delay: 0.2s; }

.hs2_box.open .hs2_col:nth-child(4):after { transition-delay: 0.3s; }

.hs2_box.open .hs2_col:nth-child(5):after { transition-delay: 0.4s; }

.hs2_box.open .hs2_col:nth-child(6):after { transition-delay: 0.5s; }

.hs2_box.open .hs2_nav, .hs2_box.open .hs2_content, .hs2_box.open .hs2_slider.open, .hs2_box.open .hs2_slider.open .hs2_item { transition-delay: 0.7s; opacity: 1; transform: none; visibility: visible; }

.hs3 { padding: 136px 0; position: relative; background-size: cover; background-position: center; background-repeat: no-repeat; background-color: #434343; color: #fff; text-align: center; }

.hs3:before, .hs3:after { content: ""; position: absolute; top: -1px; left: 0; border-top: 9.375vw solid #f9f9f9; border-right: 100vw solid transparent; }

.hs3:after { top: auto; bottom: -1px; transform: rotate(-180deg); }

.hs3 h2 { font-size: 48px; line-height: 58px; color: #fff; margin: 0 0 32px; }

.hs3 .btn, .hs3 body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .hs3 input.button, .hs3 .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_footer .hs3 input[type=submit], .hs3 .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer .hs3 input.button, .hs3 .gform_wrapper .gform_page_footer input[type=submit], .gform_wrapper .gform_page_footer .hs3 input[type=submit] { margin-bottom: 88px; }

.hs3 .row { justify-content: center; margin-bottom: 0 !important; }

.hs3_item { position: relative; padding: 50% 0; background: url("../images/circle-arrow.svg") no-repeat center; background-size: cover; margin-bottom: 24px; }

.hs3_content { position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 30.5% 15px; }

.hs3_num { font-size: 34px; line-height: 42px; font-weight: bold; }

.hs3_text { max-width: 160px; margin: auto; font-size: 18px; line-height: 26px; }

.hs3 .note { text-align: center; padding: 64px 12px 0; font-style: italic; }

.hs4 { padding: 48px 0 60px; }

.hs4_item { background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; margin: -1px 0; }

.hs4_item:before { content: ""; display: block; padding: 38.46% 0; }

.hs4_slider .owl-stage-outer { box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.25); }

.wocas { font-size: 16px; line-height: 24px; }

.wocas .title { margin-bottom: 0; }

.wocas .title h2 { margin-bottom: 0; }

.wocas .job-title:not(:last-child) { margin-bottom: 20px; }

.wocas .caption { padding: 16px; border-radius: 6px; background: #f2f2f2; }

.wocas_item { position: relative; display: flex; align-items: center; justify-content: center; padding: 32px 0 60px; }

.wocas_item_img { position: relative; width: 100%; max-width: 33.333333%; flex: 0 0 33.333333%; }

.wocas_item_img .img { background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; background-color: #f9f9f9; box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25); }

.wocas_item_img .img:before { content: ""; display: block; padding: 50% 0; }

.wocas_item_content { position: relative; width: 100%; max-width: 50%; flex: 0 0 50%; padding-left: 64px; }

.wocas_item_content .name { font-weight: bold; }

.wocas .owl-prev, .wocas .owl-next { top: calc(50% - 84px); }

.wocas .owl-prev { left: 0; }

.wocas .owl-next { right: 0; }

.wocas .owl-dots { position: relative; bottom: 60px; left: 0; width: 100%; margin: 42px 0 0; text-align: center; line-height: 4px; }

.wocas .owl-dot { height: 4px; width: 64px; background: #D8D8D8; border-radius: 0; }

.wocas .owl-dot.active { background: #B4B4B4; }

.git { color: #fff; background: #636362; position: relative; margin-top: 4.687vw; font-size: 16px; line-height: 24px; }

.git .container { padding-top: 60px; padding-bottom: 60px; }

.git:before { content: ""; position: absolute; top: calc(-4.687vw + 1px); border-bottom: 4.687vw solid #CA0538; border-right: 81.88vw solid transparent; }

.git h2 { color: #fff; }

.git h3 { font-size: 26px; line-height: 31px; margin: 0 0 8px; font-weight: bold; }

.git .title { max-width: 600px; }

.git p:not(:last-child) { margin: 0 0 35px; }

.git_box1 { background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; position: relative; height: 100%; }

.git_box1:before { content: ""; display: block; padding: 40% 0; }

.git_box1:after { content: ""; z-index: 1; display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: rgba(0, 0, 0, 0.2); background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%); }

.git_box1 .git_content { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 2; }

.git_box1 a.with-arrow { position: relative; z-index: 3; }

.git_box { background: #434343; position: relative; overflow: hidden; }

.git_box .watermark { position: absolute; height: 137px; width: 284px; top: 45px; right: -47px; z-index: 1; }

.git_box h3, .git_box p, .git_box a.with-arrow { position: relative; z-index: 2; }

.git_box a.with-arrow { z-index: 3; }

.git_box.red { background: #CA0538; }

.git_box + .git_box { margin: 24px 0 0; }

.git_content { padding: 24px; position: relative; }

.git_wos h3 { margin: 0 0 24px; }

.git_wos .more { position: absolute; bottom: 0; left: 12px; color: #FFF; cursor: pointer; display: none; }

.git_wos .more:hover { text-decoration: underline; }

.git_wos-says { overflow: hidden; }

.git_wos-says.scrollAble { padding-right: 20px; transition: all .3s ease; }

.git_wos-says.scrollAble.show { overflow-y: auto; }

.git_wos-says.scrollAble .more { display: block; }

.git_say { opacity: 0.75; padding: 16px 0; }

.git_say p:not(:last-child) { margin: 0 0 12px; }

.git_name { font-weight: bold; display: block; }

.git_job, .git_company { display: block; }

.ctus { position: relative; background-size: cover; background-position: center; background-repeat: no-repeat; padding-top: 6vw; }

.ctus:before { content: ""; position: absolute; height: 6vw; border-bottom: 0 solid transparent; border-left: 80vw solid #CA0538; border-right: 50vw solid transparent; border-top: 6vw solid transparent; top: 0; left: 0; }

.ctus_content { position: relative; z-index: 3; padding: 135px 0; background-size: cover; background-position: center; background-repeat: no-repeat; }

.ctus_content:after { content: ""; background: linear-gradient(180deg, rgba(0, 0, 0, 0.41) 0%, rgba(0, 0, 0, 0.6) 100%); position: absolute; display: block; left: 0; top: 0; width: 100%; height: 100%; z-index: 1; }

.ctus_content .container { position: relative; z-index: 2; }

.ctus .title { margin-bottom: 0; }

.ctus .title h2 { font-size: 42px; line-height: 51px; max-width: 500px; margin: 0 auto 24px; color: #FFF; }

.haq { position: fixed; bottom: 32px; right: 32px; background: #fff; padding: 24px; border-radius: 4px; box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25); z-index: 10; max-width: 220px; }

.haq h3 { color: #CA0538; font-size: 21px; line-height: 30px; margin: 0; font-weight: bold; }

.haq p { color: #636362; margin: 0 0 16px; }

.haq .btn, .haq body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .haq input.button, .haq .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_footer .haq input[type=submit], .haq .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer .haq input.button, .haq .gform_wrapper .gform_page_footer input[type=submit], .gform_wrapper .gform_page_footer .haq input[type=submit] { width: 100%; text-transform: uppercase; }

.es1 { padding-top: 48px; padding-bottom: 44px; }

.es1 .title { margin-bottom: 44px; }

.es1_img { position: relative; width: 100%; max-width: 515px; margin: 0 auto; display: flex; flex-wrap: wrap; transform: rotate(20deg); }

.es1_img #shape01 { transform: rotate(-90deg); }

.es1_img #shape03 { transform: rotate(180deg); }

.es1_img #shape04 { transform: rotate(90deg); }

.es1_img #curved01 { transform: rotate(43deg); }

.es1_img .img_logo { position: absolute; z-index: 2; width: 68%; left: 16%; top: 16%; transform: rotate(-20deg); border-radius: 50%; border: 6px solid #FFF; overflow: hidden; }

.es1_img .img_logo .img { display: none; position: relative; width: 100%; padding-top: 100%; background-size: cover; background-position: center; background-repeat: no-repeat; object-fit: cover; }

.es1_img .img_logo .img.active { display: block; }

.es1_img .img_item { position: relative; z-index: 1; width: 100%; max-width: 50%; flex: 0 0 50%; cursor: pointer; opacity: 1; transform: none; opacity: 1; transition: all .5s; }

.es1_img .img_item .box-wrap { width: 100%; padding-top: 100%; }

.es1_img .img_item svg { position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1; }

.es1_img .img_item.active { transform: scale(1.1); }

.es1_img .img_item.active .img_text { font-size: 30px; line-height: 40px; }

.es1_img .img_item:not(.active):hover { opacity: .7; }

.es1_img .img_text { position: absolute; z-index: 2; width: 100%; height: 100%; transform: rotate(45deg); top: 12px; right: 12px; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; padding-bottom: 37%; font-size: 24px; line-height: 36px; transition: all .5s; }

.es1_img .img_text.it2 { transform: rotate(45deg) scale(-1); padding-top: 37%; padding-bottom: 0; }

.es1_img .img_text span { color: #FFF; font-weight: bold; display: block; text-align: center; }

.es1_wrap { justify-content: center; align-items: center; }

.es1_wrap h3 { font-size: 36px; line-height: 43px; font-weight: bold; margin-bottom: 16px; }

.es1_wrap .col-left, .es1_wrap .col-right { margin-bottom: 20px; }

.es1 .item.collapse { display: none; }

.es1 .item.collapse.show { display: block; }

.es2 { position: relative; }

.es2_pattern { position: absolute; top: 0; left: 0; right: 0; height: 100%; background: url("../images/body-pattern.svg") no-repeat center; }

.es2 .title { margin-bottom: 32px; }

.es2_item { justify-content: center; align-items: center; margin-bottom: 44px; }

.es2_item-content { padding-left: 50px; }

.es2_item-content h3 { font-size: 21px; line-height: 30px; font-weight: bold; margin-bottom: 4px; }

.es2_item-content .date { display: block; margin-bottom: 16px; }

.es2_item:nth-child(even) { flex-direction: row-reverse; }

.es2_item:nth-child(even) .es2_item-content { padding-left: 0; padding-right: 100px; }

.es3 { margin-bottom: 50px; }

.es3 .title { margin-bottom: 32px; }

.es3 .title h2 { margin-bottom: 0; }

.es3_logo { display: flex; flex-wrap: wrap; align-items: center; position: relative; justify-content: center; }

.es3_logo_item { position: relative; width: 100%; max-width: 16.666666%; flex: 0 0 16.666666%; display: flex; padding: 0 12px; height: 100px; align-items: center; justify-content: center; margin-bottom: 16px; }

.es3_logo_item img { max-width: 100%; }

.fs { padding-top: 44px; }

.fs_title { font-size: 36px; line-height: 43px; margin-bottom: 24px; font-weight: bold; }

.fs1 { padding-bottom: 60px; }

.fs1 .title { margin-bottom: 32px; text-align: left; }

.fs1 .title p { max-width: 600px; margin: 0; }

.fsside { margin-bottom: 60px; }

.ct { padding-top: 44px; }

.ct1 { margin-bottom: 60px; }

.ctside { margin-bottom: 60px; }

.contact-list .item { display: flex; position: relative; }

.contact-list .item:not(:last-child) { margin-bottom: 48px; }

.contact-list .item_img { position: relative; display: block; width: 100%; max-width: 34%; flex: 0 0 34%; }

.contact-list .item_img .img { width: 100%; padding: 38%; background-color: #e2e2e2; background-size: cover; background-position: center; background-repeat: no-repeat; }

.contact-list .item_title { font-size: 21px; line-height: 30px; font-weight: bold; margin-bottom: 16px; }

.contact-list .item_content { position: relative; width: 100%; max-width: 66%; flex: 0 0 66%; padding-left: 24px; }

.contact-list .item_content p { margin-bottom: 8px; }

.contact-list .item_content .link { margin-bottom: 4px; }

.contact-list .item_content .link a { display: inline-block; transition: all .3s ease; }

.contact-list .item_content .link a:hover { color: #CA0538; }

.contact-list .item_content .tel a { color: #212121; }

.contact-list .item_content .mailto a { color: #636362; }

.hs2.pd { padding-top: 60px; }

@media (max-width: 991px) { .hs2.pd { padding-bottom: 120px; } }

@media (max-width: 767px) { .hs2.pd { padding-bottom: 150px; } }

.hs2_title { text-align: center; }

.hs2_title p { max-width: 856px; margin-left: auto; margin-right: auto; }

.hs2 .slider:not(.open) { position: absolute; }

.hs2_item { max-height: 500px; }

@media (max-width: 991px) { .hs2_nav { bottom: -40px; } }

.pd1 { padding: 88px 0 44px; }

.pd1_img { padding-top: 60px; }

.pd1_img img { width: 100%; }

.pd1_img-text { padding-top: 24px; font-size: 16px; line-height: 24px; }

.pd1 h2 { margin: 0 0 16px; }

.pd1 dl { margin: 0 0 32px; }

.pd1 dt, .pd1 dd { padding: 12px 0; margin: 0; }

.pd1 dt { float: left; width: 160px; font-weight: 400; }

.pd1 dd { padding-left: 108px; border-bottom: 1px solid #E2E2E2; }

.pd1 .btn, .pd1 body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .pd1 input.button, .pd1 .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_footer .pd1 input[type=submit], .pd1 .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer .pd1 input.button, .pd1 .gform_wrapper .gform_page_footer input[type=submit], .gform_wrapper .gform_page_footer .pd1 input[type=submit] { min-width: 210px; }

.pd2 { background: #F5F5F5; padding: 44px 0 60px; }

.pd2 .has-banner { padding-bottom: 130px; }

@media (max-width: 991px) { .pd2 .has-banner { padding-bottom: 0; }
  .pd2 .has-banner .pd2_banner { margin-top: 20px; position: relative; } }

.pd2_nav { margin: 0 -6px 42px; }

.pd2_nav a { line-height: 48px; padding: 0 16px; background: #D7D7D7; border-radius: 4px; color: #7e7e7e; font-size: 21px; display: inline-block; font-weight: bold; margin-left: 6px; margin-right: 6px; margin-bottom: 12px; }

.pd2_nav a.active { background: #CA0538; color: #fff; }

.pd2 .dropdown.bootstrap-select.nav-select { margin: 0 0 24px; }

.pd2 .dropdown.bootstrap-select.nav-select .bttn { background: #CA0538; color: #fff; font-weight: bold; line-height: 48px; font-size: 21px; }

.pd2_list { margin-bottom: 44px; }

.pd2_banner { margin-bottom: 38px; position: absolute; bottom: 0; }

.pd2_banner a { display: block; }

.pd2_banner img { width: 100%; }

@media (max-width: 991px) { .pd2_banner { width: calc(100% - 16px); } }

.pd2_item { min-height: 32px; padding: 4px 12px 20px 50px; color: #636362; }

.pd2_item img { position: absolute; left: 12px; top: 9px; width: 20px; height: 20px; }

.pd2 .link { color: #636362; text-decoration: underline; display: none; padding: 0 15px; }

.pd2 .link:hover { color: #CA0538; }

.pd2_img { background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.25); }

.pd2_img:before { content: ""; display: block; padding: 40% 0; }

.pd2_content { padding-right: 54px; }

.pd2 .slider:not(.open) { display: none !important; }

.pd2 .slider { padding-bottom: 30px; }

.pd2 .owl-height { transition: none !important; }

.pd2 .owl-nav { top: 50%; left: 0; right: 0; width: 100%; position: absolute; }

.pd2 .owl-prev { background: #FFF url("../images/ic-chevron-right.svg") no-repeat center; opacity: .5; background-size: 50px; width: 30px; top: -40px; left: 0; transition: all .5s ease-in-out; }

.pd2 .owl-prev:hover { opacity: 1; }

.pd2 .owl-next { background: #FFF url("../images/ic-chevron-right.svg") no-repeat center; opacity: .5; background-size: 50px; width: 30px; top: -40px; right: 0; transition: all .5s ease-in-out; }

.pd2 .owl-next:hover { opacity: 1; }

.pd2 .owl-dots { position: absolute; bottom: 0; left: 0; width: 100%; margin: 0; text-align: center; line-height: 4px; }

.pd2 .owl-dot { height: 4px; width: 64px; background: #D8D8D8; border-radius: 0; }

.pd2 .owl-dot.active { background: #B4B4B4; }

.pd3 { background: #CA0538; color: #fff; position: relative; }

.pd3 .tab-content { padding-right: 42px; }

.pd3 .container { padding: 60px 12px 80px; }

.pd3 .map { position: absolute; top: 0; right: 0; width: calc(50% - 12px); height: 100%; background-color: #eee; }

.pd3_nav { margin: 0 0 24px; }

.pd3_nav li { display: inline-block; }

.pd3_nav a { font-size: 26px; line-height: 31px; padding-bottom: 4px; display: block; border-bottom: 5px solid #fff; min-width: 220px; color: #fff; font-weight: bold; text-align: center; }

.pd3_nav a:not(.active) { opacity: 0.5; border-color: transparent; }

.pd3_item { position: relative; padding-left: 40px; }

.pd3_item:not(:last-child) { margin: 0 0 32px; }

.pd3_item img { position: absolute; left: 0; top: 0; max-width: 24px; max-height: 24px; }

.pd3_item h3 { font-size: 21px; line-height: 30px; margin: 0; }

.pd3_item ul { margin-top: 12px; list-style: none; padding-left: 0; }

.pd3_item ul li { border-bottom: 1px solid rgba(226, 226, 226, 0.5); padding: 8px 0; }

.pd3_item ul li:first-child:last-child { border: 0; }

.pd3_item ul span { display: block; }

.pd4 { padding: 60px 0 0; position: relative; }

.pd5 { padding-top: 0 !important; }

.page-not-found { position: relative; display: flex; align-items: center; justify-content: center; width: 100vw; height: 100vh; padding-bottom: 6vw; background: linear-gradient(0deg, #F5FDFF 0%, #B3D9E4 100%); }

.page-not-found_content { position: relative; z-index: 2; text-align: center; overflow: hidden; padding: 32px; }

.page-not-found_content h1 { font-size: 180px; line-height: 180px; font-weight: bold; margin-bottom: 32px; color: #CA0538; }

.page-not-found_content p { font-size: 18px; line-height: 26px; font-weight: bold; }

.page-not-found_pattern { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: url("../images/body-pattern.svg") no-repeat center; background-size: contain; transform: scale(0.9); z-index: 1; }

.page-not-found:before { content: ""; position: absolute; height: 6vw; border-bottom: 0 solid transparent; border-left: 80vw solid #CA0538; border-right: 50vw solid transparent; border-top: 6vw solid transparent; bottom: 0; left: 0; }

.ab1 { padding-top: 44px; padding-bottom: 60px; }

.milestone .title { margin-bottom: 62px; }

.milestone-wrap { display: flex; position: relative; max-width: 1045px; margin: 0 auto; }

.milestone-nav { width: 100%; max-width: 130px; flex: 0 0 130px; }

.milestone-nav .nav { list-style: none; margin: 0; padding: 0; }

.milestone-nav .nav a { display: block; padding-bottom: 18px; font-size: 15px; line-height: 24px; color: #636362; position: relative; padding-left: 22px; }

.milestone-nav .nav a:after { content: ""; display: block; width: 10px; height: 10px; background-color: #9c9c9c; border-radius: 5px; position: absolute; top: 7px; left: 0; }

.milestone-nav .nav a:before { content: ""; display: block; height: 100%; width: 1px; background-color: #636362; opacity: 0.2; position: absolute; top: 7px; left: 4.5px; }

.milestone-nav .nav a:last-child:before { display: none; }

.milestone-nav .nav a.active { font-size: 21px; color: #CA0538; font-weight: bold; padding-bottom: 35px; }

.milestone-nav .nav a.active:after { width: 13px; height: 13px; border-radius: 6.5px; left: -1.5px; top: 5.5px; border: 2px solid #CA0538; background-color: #FFF; }

.milestone-content { position: relative; width: 100%; max-width: calc(50% - 130px); flex: 0 0 calc(50% - 130px); padding-right: 48px; }

.milestone-content h3 { font-size: 21px; line-height: 30px; font-weight: bold; margin-bottom: 16px; }

.milestone-img { position: relative; width: 100%; max-width: 50%; flex: 0 0 50%; }

.milestone-img .img { background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; margin: -1px 0; box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.25); }

.milestone-img .img:before { content: ""; display: block; padding: 35% 0; }

.ab2 { padding: 60px 0; background-color: #EFF3F7; }

.ab2 h2 { font-size: 36px; line-height: 43px; color: #212121; margin-bottom: 16px; }

.ab2-content { padding-left: 68px; }

.ab2-chart { width: 100%; position: relative; }

.ab2-chart canvas { pointer-events: none; }

.ab2-chart .logo { display: block; width: 60%; height: 60%; border-radius: 50%; background-color: #eff3f7; position: absolute; top: 20%; left: 20%; z-index: 2; display: flex; align-items: center; justify-content: center; }

.ab2-chart .logo img { max-width: 145px; }

.ab2-chart-legend { column-count: 2; margin-bottom: 32px; }

.ab2-chart-legend li { padding-left: 40px; margin-bottom: 8px; position: relative; }

.ab2-chart-legend li .icon { display: block; width: 24px; height: 24px; position: absolute; top: 4px; left: 0; background-color: #FFF; border-radius: 12px; }

.ab3 { padding: 164px 0; position: relative; color: #fff; text-align: center; }

.ab3 .container { position: relative; z-index: 3; }

.ab3-bg { position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-size: cover; background-position: center; background-repeat: no-repeat; background-color: #434343; z-index: 1; }

.ab3-bg:after { content: ""; position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.62) 100%); }

.ab3:before, .ab3:after { content: ""; position: absolute; top: -1px; left: 0; border-top: 9.375vw solid #f9f9f9; border-right: 100vw solid transparent; z-index: 2; }

.ab3:before { border-top-color: #EFF3F7; }

.ab3:after { top: auto; bottom: -1px; transform: rotate(-180deg); }

.ab3 h2 { font-size: 48px; line-height: 58px; color: #fff; margin: 0 auto 32px; max-width: 900px; }

.ab3 small { display: block; font-size: 32px; line-height: 42px; font-weight: bold; color: #FFF; margin: 0 auto; max-width: 900px; }

.ab3 .btn, .ab3 body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .ab3 input.button, .ab3 .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_footer .ab3 input[type=submit], .ab3 .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer .ab3 input.button, .ab3 .gform_wrapper .gform_page_footer input[type=submit], .gform_wrapper .gform_page_footer .ab3 input[type=submit] { margin-bottom: 88px; }

.ab3 .row { justify-content: center; }

.ab3-item { padding: 0 32px; }

.ab3-item h3 { font-size: 18px; line-height: 26px; }

.ab3-item p { font-size: 18px; line-height: 26px; }

.ab3-icon { padding: 20px; text-align: center; }

.ab3-icon img { height: 60px; width: auto; margin: 0 auto; }

.ab3-extra { padding: 164px 0 188px; }

.ab3-extra:before { border-top-color: #f9f9f9; }

.ab3-extra:after { border-top-color: #f5f5f5; }

.ab4 { padding: 48px 0 60px; }

.ab4_item { background-size: cover; background-position: center; background-color: #434343; background-repeat: no-repeat; display: block; margin: -1px 0; }

.ab4_item:before { content: ""; display: block; padding: 38.46% 0; }

.ab4_slider .owl-stage-outer { box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.25); }

.cr1 { padding: 44px 0 60px; }

.cr1 .title { margin: 0; }

.cr2_item { margin-bottom: 44px; font-size: 15px; line-height: 24px; }

.cr2_item-img { position: relative; width: 100%; margin-bottom: 16px; }

.cr2_item-img .img { width: 100%; padding-top: 56.122%; background-size: cover; background-position: center; background-repeat: no-repeat; }

.cr2_item-title { margin-bottom: 8px; font-size: 15px; line-height: 24px; display: block; color: #212121; font-weight: bold; transition: all .5s; }

.cr3 { padding-bottom: 60px; }

.cr3 .title { margin-bottom: 24px; }

.cr3_container { max-width: 808px; margin-left: auto; margin-right: auto; padding-right: 12px; padding-left: 12px; }

.no-result { list-style: none; display: block; width: 100%; text-align: center; padding: 48px 0; font-weight: normal; }

.accordion-careers .card-header { font-size: 15px; line-height: 24px; }

.accordion-careers .card-header-wrap { display: flex; position: relative; cursor: pointer; padding-right: 18px; }

.accordion-careers .card-header-wrap:after { content: ""; position: absolute; display: block; right: 0; top: 0; width: 20px; height: 20px; background-image: url("../images/ic-chevron-right.svg"); background-size: 200%; background-position: center; background-repeat: no-repeat; transform: rotate(90deg); transition: transform .3s ease; }

.accordion-careers .card-header-wrap.collapsed:after { transform: rotate(0); }

.accordion-careers .card-header-wrap-left { width: 100%; position: relative; flex: 0 0 65%; max-width: 65%; }

.accordion-careers .card-header-wrap-right { width: 100%; position: relative; flex: 0 0 35%; max-width: 35%; }

.accordion-careers .card-header .title { font-size: 15px; line-height: 24px; display: block; text-align: left; margin: 0; }

.accordion-careers .card-header .subcategory { display: block; color: #636362; margin: 8px 0 0; }

.accordion-careers .card-header .location { display: block; color: #636362; position: relative; }

.accordion-careers .card-header .location:before { content: ""; display: block; position: absolute; width: 9px; height: 12px; background-image: url(../images/icon-marker.svg); background-size: contain; background-position: center; background-repeat: no-repeat; left: -17px; top: 6px; }

.accordion-careers .card-header .closes { margin: 8px 0 0; display: block; color: #CA0538; }

.accordion-careers .card-body { font-size: 15px; line-height: 24px; padding: 12px 24px; }

.accordion-careers .card-body h4 { font-weight: bold; margin: 0 0 11px; }

.accordion-careers .card-body p { margin: 0 0 32px; }

.loading { position: relative; }

.loading:after { content: ""; display: block; position: absolute; z-index: 2; width: calc(100% + 32); height: calc(100% + 32); top: -16px; right: -16px; bottom: -16px; left: -16px; border-radius: 4px; background: rgba(255, 255, 255, 0.6); }

.loading:before { content: ""; display: block; position: absolute; z-index: 3; width: 60px; height: 60px; top: calc(50% - 30px); left: calc(50% - 30px); background-image: url(../images/loading-search.svg); background-size: contain; background-position: center; background-repeat: no-repeat; }

/* ------------------------------------------------------------------------------------------------ */
/* Footer */
/* ------------------------------------------------------------------------------------------------ */
.footer { padding: 72px 0 44px; background: #595959; color: #fff; font-size: 15px; line-height: 24px; }

.footer .has-sub > a { font-size: 16px; line-height: 24px; font-weight: bold; margin: 0 0 8px; pointer-events: none; display: block; }

.footer a { color: #fff; }

.footer a:hover { opacity: 0.5; }

.footer_social a { display: block; }

.footer_social .fa { font-size: 18px; line-height: 18px; margin-right: 16px; width: 18px; }

.footer .dropdown.bootstrap-select { display: inline-block; color: #fff; }

.footer .dropdown.bootstrap-select .bttn { border: none; line-height: 24px; }

.footer .dropdown.bootstrap-select .bttn:after { line-height: 24px; top: 0; }

@media (min-width: 2200px) { .hero { min-height: 65vh !important; }
  .hero-about { min-height: 65vh !important; } }

@media (max-width: 1700px) { .hero-about { padding-bottom: 380px; } }

@media (max-width: 1500px) { .hero-about { padding-bottom: 320px; } }

@media (max-width: 1370px) and (min-width: 770px) { .hero { max-height: 100vh; }
  .hero_slider { max-height: initial; } }

@media (min-width: 1280px) { .container { max-width: 1224px; } }

@media (min-width: 426px) { .footer_copy .text-right { display: none; } }

@media (min-width: 1200px) { .hs2_title .col-md-6 { padding-left: 52px; }
  footer .col-company { margin-right: 6.5%; } }

@media (max-width: 1200px) and (min-width: 992px) { .footer .col-company, .footer .order-lg-6 { margin-top: 24px; } }

@media (max-width: 1200px) { html, body { font-size: 16px; line-height: 24px; }
  .header { z-index: 4; top: 0; }
  .header .container { max-width: 100%; }
  .header_logo { max-width: 100px; }
  .header_left-menu, .header_right-menu { position: relative; top: 0; left: 0; width: 100%; text-align: left; padding: 16px; }
  .header_left-menu > li:not(.li), .header_right-menu > li:not(.li) { display: block; margin: 0; padding: 8px 0; opacity: 0; display: block; margin: 0; padding: 8px 0; transform: scale(1.1) translateY(-24px); transition: opacity 0.35s ease-out, transform 0.35s ease-out, -webkit-transform 0.35s ease-out; }
  .header_left-menu > li:not(.li) a, .header_right-menu > li:not(.li) a { display: inline-block; padding: 0; }
  .header .has-sub > .sub-menu { position: relative; }
  .header .has-sub2 > a:after { content: "\f0d7" !important; position: absolute !important; right: 6px !important; top: 9px !important; }
  .header .has-sub2 > .sub-menu { position: relative; }
  .header .has-sub2.sub-open > .sub-menu { display: block; left: 0; top: 0; }
  .header .header_top-menu { position: relative; top: auto; right: auto; width: 100%; padding: 16px 16px 10px; text-align: left; }
  .header .header_top-menu .has-sub > a { color: #FFF; }
  .header .header_top-menu .dropdown.bootstrap-select .bttn { color: #FFF; }
  .header_nav { background: rgba(0, 0, 0, 0.5); margin: 0 -12px; max-height: 0; visibility: hidden; overflow: hidden; transition: all .5s; }
  .header_left-menu { padding-bottom: 0; }
  .header_right-menu { padding-top: 0 !important; }
  .header li .dropdown.bootstrap-select { display: inline-block; padding-right: 23px; position: relative; }
  .header li .dropdown.bootstrap-select .bttn { padding: 0; width: auto; }
  .header li .dropdown.bootstrap-select div.dropdown-menu { top: calc(100% - 24px) !important; }
  .header li .dropdown.bootstrap-select div.dropdown-menu:after, .header li .dropdown.bootstrap-select div.dropdown-menu:before { display: none; }
  .header .header_top-menu { position: absolute; width: 100%; bottom: -60px; padding: 0; border-top: 1px solid rgba(255, 255, 255, 0.3); display: flex; }
  .header .header_top-menu > li { display: block; width: 100%; flex: 0 0 50%; max-width: 50%; position: relative; padding: 15px 16px; margin: 0; }
  .header .header_top-menu > li + li { margin: 0; border-left: 1px solid rgba(255, 255, 255, 0.3); }
  .header .header_top-menu > li .dropdown.bootstrap-select .bttn:after { top: 0; }
  .header .header_top-menu .menu-lang { display: block; }
  .header .header_top-menu .has-sub > a { padding: 0; border: 0; }
  .header li .dropdown.bootstrap-select { margin-bottom: 0; }
  .header_right-menu .menu-lang { display: none !important; }
  .burger-menu { display: block; }
  body.menu-open:after { content: ""; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.8); z-index: 3; }
  body.menu-open .header { height: 100%; width: 100%; top: 0; left: 0; bottom: 0; background: transparent; }
  body.menu-open .header > .container { background: #bc4269; }
  body.menu-open .header .menu-lang, body.menu-open .header .header_top-menu { opacity: 1 !important; }
  body.menu-open .header .header_top-menu { bottom: 0; }
  body.menu-open .header_nav { max-height: 100vh; visibility: visible; overflow-x: hidden; overflow-y: auto; padding-bottom: 60px; }
  body.menu-open .header_left-menu > li, body.menu-open .header_right-menu > li { opacity: 1; transform: none; }
  body.menu-open .header_left-menu > li:nth-of-type(1), body.menu-open .header_right-menu > li:nth-of-type(1) { -webkit-transition-delay: 250ms, 250ms !important; -moz-transition-delay: 250ms, 250ms !important; -o-transition-delay: 250ms, 250ms !important; -ms-transition-delay: 250ms, 250ms !important; transition-delay: 250ms, 250ms !important; }
  body.menu-open .header_left-menu > li:nth-of-type(2), body.menu-open .header_right-menu > li:nth-of-type(2) { -webkit-transition-delay: 300ms, 300ms !important; -moz-transition-delay: 300ms, 300ms !important; -o-transition-delay: 300ms, 300ms !important; -ms-transition-delay: 300ms, 300ms !important; transition-delay: 300ms, 300ms !important; }
  body.menu-open .header_left-menu > li:nth-of-type(3), body.menu-open .header_right-menu > li:nth-of-type(3) { -webkit-transition-delay: 350ms, 350ms !important; -moz-transition-delay: 350ms, 350ms !important; -o-transition-delay: 350ms, 350ms !important; -ms-transition-delay: 350ms, 350ms !important; transition-delay: 350ms, 350ms !important; }
  body.menu-open .header_left-menu > li:nth-of-type(4), body.menu-open .header_right-menu > li:nth-of-type(4) { -webkit-transition-delay: 400ms, 400ms !important; -moz-transition-delay: 400ms, 400ms !important; -o-transition-delay: 400ms, 400ms !important; -ms-transition-delay: 400ms, 400ms !important; transition-delay: 400ms, 400ms !important; }
  body.menu-open .header_left-menu > li:nth-of-type(5), body.menu-open .header_right-menu > li:nth-of-type(5) { -webkit-transition-delay: 450ms, 450ms !important; -moz-transition-delay: 450ms, 450ms !important; -o-transition-delay: 450ms, 450ms !important; -ms-transition-delay: 450ms, 450ms !important; transition-delay: 450ms, 450ms !important; }
  body.menu-open .header_left-menu > li:nth-of-type(6), body.menu-open .header_right-menu > li:nth-of-type(6) { -webkit-transition-delay: 500ms, 500ms !important; -moz-transition-delay: 500ms, 500ms !important; -o-transition-delay: 500ms, 500ms !important; -ms-transition-delay: 500ms, 500ms !important; transition-delay: 500ms, 500ms !important; }
  body.menu-open .header_left-menu > li:nth-of-type(7), body.menu-open .header_right-menu > li:nth-of-type(7) { -webkit-transition-delay: 550ms, 550ms !important; -moz-transition-delay: 550ms, 550ms !important; -o-transition-delay: 550ms, 550ms !important; -ms-transition-delay: 550ms, 550ms !important; transition-delay: 550ms, 550ms !important; }
  body.menu-open .header_left-menu > li:nth-of-type(8), body.menu-open .header_right-menu > li:nth-of-type(8) { -webkit-transition-delay: 600ms, 600ms !important; -moz-transition-delay: 600ms, 600ms !important; -o-transition-delay: 600ms, 600ms !important; -ms-transition-delay: 600ms, 600ms !important; transition-delay: 600ms, 600ms !important; }
  body.menu-open .header_left-menu > li:nth-of-type(9), body.menu-open .header_right-menu > li:nth-of-type(9) { -webkit-transition-delay: 650ms, 650ms !important; -moz-transition-delay: 650ms, 650ms !important; -o-transition-delay: 650ms, 650ms !important; -ms-transition-delay: 650ms, 650ms !important; transition-delay: 650ms, 650ms !important; }
  body.menu-open .header_left-menu > li:nth-of-type(10), body.menu-open .header_right-menu > li:nth-of-type(10) { -webkit-transition-delay: 700ms, 700ms !important; -moz-transition-delay: 700ms, 700ms !important; -o-transition-delay: 700ms, 700ms !important; -ms-transition-delay: 700ms, 700ms !important; transition-delay: 700ms, 700ms !important; }
  .hero { padding-top: 180px; min-height: 750px; max-height: none; }
  .hero_img { width: 60%; }
  .hero-about { padding-bottom: 220px; }
  .hero_detail { padding-top: 150px; }
  .hero_slider { padding-top: 120px; padding-bottom: 120px; }
  .hs1 { padding: 88px 0; }
  .hs1 .title { margin-bottom: 48px; }
  .hs1 .owl-stage-outer { padding: 0; }
  .hs2_title { margin-bottom: 48px; }
  .hs4 { padding: 88px 0; }
  .wocas_item { padding-top: 48px; padding-bottom: 120px; }
  .es1_img { margin-bottom: 48px; }
  .es2_item-content { padding-left: 30px; }
  .es2_item:nth-child(even) .es2_item-content { padding-right: 30px; }
  .pd1 { padding-top: 30px; }
  .pd5 { padding-top: 0; } }

@media (max-width: 1200px) and (min-width: 1000px) { .hs3_content { padding: 33.5% 15px; }
  .hs3_num { font-size: 42px; }
  .hs3_item { padding: 40% 0; margin: 0 55px; background-size: contain; }
  .hero_slider { min-height: auto; } }

@media (max-width: 992px) and (min-width: 768px) { .hs3 .container { max-width: 600px; }
  .hs3_item { margin: 0 0 24px; }
  .hs2_box:before { padding: 36.74% 0; }
  .git_wos { margin: 24px 0 0; }
  .footer .col-company, .footer .order-lg-6, .footer .order-lg-1 { margin-bottom: 24px; }
  .hero_detail p { font-size: 16px; line-height: 24px; }
  .pd1_text { padding: 30px 12px 0; }
  .pd1_img { padding-top: 40px; }
  .pd2_img { margin: 0; }
  .pd4, .pd5 { padding: 88px 0; }
  .pd5 { padding-top: 0; }
  .pd4.wocas { padding-bottom: 0; margin: 0; }
  .pd4.wocas .wocas_item { padding-bottom: 88px; }
  .pd3 .container { padding: 88px 12px; }
  .ab3-item { padding: 0 16px; } }

@media (max-width: 991px) { .hero_slider { padding: 120px 0 100px; min-height: auto; }
  .hero_slider.overlay .hero_item:after { display: none; }
  .hero_slider.overlay .content { color: #212121; display: block; }
  .hero_slider .hero_item { box-shadow: none; }
  .hero_slider .hero_item .bg_img { position: relative; top: auto; padding-top: 39%; }
  .hero_slider .hero_item .content { max-width: 100%; min-height: auto; padding: 0 0 30px; }
  .hero_slider .hero_item .content:before { display: none; }
  .hero_detail .img2 { height: calc(100% - 84px); }
  .hero_detail .img3 { margin-top: 20px; }
  .hero:before { bottom: 0; }
  .hero-about article { margin-left: 0; }
  .hidden-md-down { display: none; }
  .hs2 { padding-top: 0; padding-bottom: 232px; }
  .hs2_box.open { margin-bottom: 0; }
  .hs2_col + .hs2_col { border: none; }
  .hs2_nav { opacity: 1; visibility: visible; }
  .hs2_slider:not(.owl-loaded) .hs2_item { max-width: calc(50% - 24px); flex: 0 0 calc(50% - 24px); }
  .wocas { margin-bottom: 88px; }
  .wocas .title { margin-bottom: 32px; }
  .wocas_item { flex-wrap: wrap; padding: 0 32px 32px; }
  .wocas_item_img { max-width: 40%; flex: 0 0 40%; padding: 0; }
  .wocas_item_img .img { box-shadow: none; }
  .wocas_item_content { max-width: 60%; flex: 0 0 60%; padding: 24px 0 0 16px; }
  .wocas .owl-prev { left: -32px; }
  .wocas .owl-next { right: -32px; }
  .wocas .owl-dots { bottom: 50px; }
  .pd4 .owl-dots { bottom: 90px; }
  .es2_item-content { padding-left: 0; }
  .es2_item:nth-child(even) .es2_item-content { padding-right: 0; }
  .fs1 { padding-bottom: 64px; }
  .fsside { margin-bottom: 88px; }
  .ct1 { margin-bottom: 64px; }
  .ctside { margin-bottom: 88px; }
  .pd2_content { padding-right: 0; }
  .pd2_nav { margin-bottom: 32px; }
  .pd2_nav a { padding: 0 14px; font-size: 16px; }
  .pd2 .owl-nav { top: 45%; }
  .pd3 .col-lg-6 { max-width: 50%; flex: 0 0 50%; }
  .pd3_nav a { padding-left: 10px; padding-right: 10px; font-size: 16px; min-width: auto; }
  .ab1 { padding-bottom: 88px; }
  .ab1 .title { margin-bottom: 24px; }
  .ab2 { padding: 88px 0; }
  .ab2-content { padding-left: 12px; }
  .ab4 { padding: 88px 0; }
  .milestone-wrap { flex-wrap: wrap; }
  .milestone-nav { max-width: 100%; flex: 0 0 100%; }
  .milestone-nav .nav { display: flex; }
  .milestone-nav .nav a { display: block; width: 100%; padding: 32px 0 48px; text-align: center; }
  .milestone-nav .nav a:after { top: 10px; left: calc(50% - 5px); }
  .milestone-nav .nav a:before { width: 100%; height: 1px; top: 5px; left: calc(50% - 5px); }
  .milestone-nav .nav a.active { padding: 32px 0; }
  .milestone-nav .nav a.active:after { top: 8.5px; left: calc(50% - 6.5px); }
  .milestone-content { max-width: 50%; flex: 0 0 50%; }
  .ab2-content { padding-left: 0; }
  .ab2-chart { margin: 0 auto 32px; }
  .ab2-chart-legend { column-count: 1; }
  .ab2-chart-legend li { padding-left: 32px; }
  .ab2-chart-legend li .icon { width: 18px; height: 18px; top: 3px; }
  .git .container { padding-bottom: 60px; }
  .cr3_container { max-width: 720px; } }

@media (min-width: 992px) { .hero_detail .img3 { margin-top: 30px; }
  .hero_detail .container > .row { margin-bottom: -14vw; }
  .pd1_text { padding: 30px 0 0 48px; } }

@media (min-width: 768px) { .visible-xs { display: none; }
  .ct .sidebar { padding: 75px 24px; } }

@media (max-width: 767px) { .hero_img { width: 51%; }
  .hidden-xs { display: none; }
  .hero.hero_detail { padding-bottom: 60px; }
  .hero.hero_detail h1 { margin-bottom: 16px; }
  .hero.hero_detail .slider { margin-top: 24px; }
  h2 { font-size: 30px; line-height: 36px; }
  .title { margin-bottom: 32px; }
  .owl-dots { text-align: center; }
  [class*="mb-"] { margin-bottom: 24px; }
  .hero { height: auto; padding: 120px 0 175px; min-height: 0; }
  .hero h1 { font-size: 32px; line-height: 40px; }
  .hero p { font-size: 16px; line-height: 24px; }
  .hero_slider { padding-bottom: 80px; }
  .hero_slider .container { padding-left: 15px; padding-right: 15px; }
  .hs1 { padding: 48px 0; }
  .hs1 .col-md-3 + .col-md-3 { margin-top: 16px; }
  .hs1_text, .hs1_bg:before { opacity: 1; }
  .hs1_item:before { padding: 60% 0; }
  .hs1 .slider { padding: 0 24px; }
  .hs1 .slider .owl-prev, .hs1 .slider .owl-next { right: -24px; }
  .hs1 .slider .owl-prev { left: -24px; right: auto; }
  .hs1_desc { opacity: 1; max-height: 400px; }
  .hs1_text:after { opacity: 1; right: 0; }
  .hs2 { padding-top: 0; padding-bottom: 225px; margin-bottom: 48px; }
  .hs2_box:before { padding: 30%; transition: all .3s; }
  .hs2_box.open:before { padding: 64.22%; }
  .hs2_nav { top: 100%; }
  .hs2_nav a { font-size: 16px; }
  .hs2_content { background-color: #F9F9F9; }
  .hs2_content .slider { padding: 0 24px; }
  .hs2_content .owl-prev, .hs2_content .owl-next { right: -24px; }
  .hs2_content .owl-prev { left: -24px; right: auto; }
  .hs3 { padding: 64px 0; }
  .hs3 .btn, .hs3 body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .hs3 input.button, .hs3 .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_footer .hs3 input[type=submit], .hs3 .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer .hs3 input.button, .hs3 .gform_wrapper .gform_page_footer input[type=submit], .gform_wrapper .gform_page_footer .hs3 input[type=submit] { margin-bottom: 12px; }
  .hs3 .row { margin-bottom: 14px; }
  .hs3 h2 { font-size: 30px; line-height: 36px; margin: 0 0 24px; }
  .hs3 [class*="col-"]:not(:last-child) { margin: 0 0 24px; }
  .hs3 .owl-dots { margin: 0; }
  .hs3_text { font-size: 16px; line-height: 22px; }
  .hs3_content { padding: 35.5% 15px; }
  .hs3_num { font-size: 34px; line-height: 46px; }
  .hs3_item { padding: 36% 0; margin: 0 65px; background-size: contain; }
  .wocas_item_img, .wocas_item_content { max-width: 100%; flex: 0 0 100%; padding: 0; }
  .wocas_item_content { padding-top: 24px; }
  .wocas .owl-prev, .wocas .owl-next { top: 340px; }
  .git .container, .hs4 .container { padding-top: 48px; padding-bottom: 48px; }
  .git [class*="col-"]:not(:last-child), .hs4 [class*="col-"]:not(:last-child) { margin: 0 0 24px; }
  .git .container { padding-bottom: 0; }
  .git_box1 .git_content { position: relative; }
  .git_box1:before { display: none; }
  .ctus .title h2 { font-size: 30px; line-height: 36px; }
  .es1 { padding-top: 80px; }
  .es1 .title { margin-bottom: 44px; }
  .es1_img { max-width: calc(100% - 23px); margin-bottom: 14px; }
  .es1 .es1_img .img_text { font-size: 14px; line-height: 20px; padding-bottom: 43%; }
  .es1 .es1_img .img_text.it2 { padding-bottom: 0; padding-top: 42%; }
  .es1 .es1_img .img_item.active { transform: scale(1.1); }
  .es1 .es1_img .img_item.active .img_text { font-size: 16px; line-height: 24px; }
  .es2 { margin-bottom: 88px; }
  .es2 .title { margin-bottom: 32px; }
  .es2_item { margin-bottom: 64px; }
  .es2_item-content { padding-top: 32px; }
  .es3 .title { margin-bottom: 32px; }
  .es3_logo_item { max-width: 33.333333%; flex: 0 0 33.333333%; height: 75px; }
  .fs { padding-top: 48px; }
  .fs .row { flex-direction: column-reverse; }
  .fs_title { font-size: 28px; line-height: 36px; margin-bottom: 12px; }
  .footer { padding: 48px 0 24px; }
  .footer_logo { max-width: 300px; margin: auto; display: block; }
  .footer .order-lg-1, .footer .order-lg-6 { margin-bottom: 24px; }
  .footer .col-menu h3 { position: relative; cursor: pointer; margin-top: 8px; }
  .footer .col-menu h3:after { content: "+"; position: absolute; right: 0; top: 0; transition: all .5s; }
  .footer_menu { border-bottom: 1px dotted #e2e2e2; max-height: 0; overflow: hidden; transition: all .5s; }
  .footer .expand h3:after { transform: rotate(45deg); }
  .footer .expand .footer_menu { max-height: 100vh; }
  .footer .menu .has-sub > a { position: relative; display: block; margin: 10px 0; font-weight: normal; }
  .footer .menu .has-sub > a:after { content: "+"; position: absolute; right: 0; top: 0; transition: all .5s; }
  .footer .menu .sub-menu { border-bottom: 1px dotted #e2e2e2; max-height: 0; overflow: hidden; transition: all .5s; padding: 0; }
  .footer .menu .has-sub.sub-open > a:after { transform: rotate(45deg); }
  .footer .menu .has-sub.sub-open .sub-menu { max-height: 100vh; padding-bottom: 10px; }
  .contact-list .item { flex-direction: column; }
  .contact-list .item_img, .contact-list .item_content { max-width: 100%; flex: 0 0 100%; }
  .contact-list .item_content { padding: 24px 0 0; }
  .pd1 { padding: 48px 0; }
  .pd1_text { margin-top: 32px; }
  .pd1_img { padding-top: 0; }
  .pd2 { padding: 48px 0; }
  .pd2_img { margin: 0; }
  .pd2 .owl-carousel { margin-bottom: 32px; }
  .pd2 .owl-nav { top: 45%; }
  .pd2_list { margin-bottom: 0; }
  .pd2_item:last-child { padding-bottom: 0; }
  .pd3 { display: flex; flex-direction: column-reverse; padding-top: 0; }
  .pd3 .col-lg-6 { max-width: 100%; flex: 0 0 100%; }
  .pd3 .map { height: 300px; position: relative; width: 100%; }
  .pd3 .container { padding: 48px 12px; }
  .pd3_nav li { min-width: 0; }
  .pd3_nav a { min-width: 0; padding: 0 10px; font-size: 20px; }
  .pd4 { margin: 0; padding: 48px 0 0; }
  .pd4 .wocas_item { padding-bottom: 48px; }
  .pd4.wocas .owl-dots { bottom: 50px; }
  .pd5 { padding-top: 0; }
  .ab1, .ab2 { padding: 48px 0; }
  .ab2 h2 { font-size: 30px; line-height: 36px; }
  .ab2-chart { width: 70%; }
  .ab2-chart .logo { width: 40%; height: 40%; top: 30%; left: 30%; }
  .ab2-chart .logo img { max-width: 100%; }
  .ab3 { padding: 96px 0; }
  .ab3 .btn, .ab3 body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .ab3 input.button, .ab3 .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_footer .ab3 input[type=submit], .ab3 .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer .ab3 input.button, .ab3 .gform_wrapper .gform_page_footer input[type=submit], .gform_wrapper .gform_page_footer .ab3 input[type=submit] { margin-bottom: 48px; }
  .ab3 .row { margin-bottom: 0; }
  .ab3 h2 { font-size: 30px; line-height: 36px; }
  .ab4 { padding: 48px 0; }
  .ab4 [class*="col-"]:not(:last-child) { margin-bottom: 24px; }
  .milestone-content, .milestone-img { max-width: 100%; flex: 0 0 100%; }
  .milestone-content { padding: 0 0 48px; }
  .milestone-nav { padding-left: 50px; padding-right: 50px; }
  .milestone-nav .owl-prev, .milestone-nav .owl-next { width: 60px; height: 60px; top: 0; }
  .milestone-nav .owl-prev { left: -50px; }
  .milestone-nav .owl-next { right: -50px; }
  .cr1 { padding: 88px 0; }
  .cr2_item { margin-bottom: 48px; }
  .cr3 { padding-bottom: 88px; }
  .accordion-careers .card-header-wrap { flex-wrap: wrap; }
  .accordion-careers .card-header-wrap-left, .accordion-careers .card-header-wrap-right { max-width: 100%; flex: 0 0 100%; }
  .accordion-careers .card-header-wrap .subcategory, .accordion-careers .card-header-wrap .location, .accordion-careers .card-header-wrap .closes { margin-top: 6px; }
  .accordion-careers .card-header-wrap .location { padding-left: 16px; }
  .accordion-careers .card-header-wrap .location:before { left: 0; } }

@media (max-width: 426px) { .hero_img { width: 75%; } }

@media (max-width: 340px) { .hs3_num { font-size: 24px; line-height: 36px; }
  .hs3_content { padding-top: 40.5%; }
  .es1 .es1_img .img_text { padding-bottom: 46%; }
  .es1 .es1_img .img_text.it2 { padding-top: 45%; } }
