/* ------------------------------------------------------------------------------------------------ */
/* Form */
/* ------------------------------------------------------------------------------------------------ */

label {
  display: block; }
.form-group {
  margin: 0 0 16px;
  color: #485460;
  label:not(.error) {
    color: #636362;
    font-weight: 400;
    margin: 0;
    -webkit-appearance: none;
    font-size: 18px;
    line-height: 26px;
    span {
      font-weight: 400;
 } }      //font-size: 12px

  a.right {
    float: right;
    color: #485460;
    font-weight: 400;
    &:hover {
      text-decoration: underline; } } }
.error {
  font-weight: 400;
  padding: 4px 0;
  text-align: left;
  margin: -10px 0 15px;
  color: $red;
  &:before {
    content: "\f06a";
    font-family: fontAwesome;
    margin-right: 4px; } }
form {
  small {
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 0 0;
    &.info {
      font-size: 12px; } } }

.field {
  display: block;
  width: 100%;
  height: 36px;
  border: none;
  border-bottom: 1px solid rgba(216,216,216,0.5);
  background: transparent;
  padding: 8px 0;
  font-size: 15px;
  line-height: 24px;
  -webkit-appearance: none; }

.help-text {
  opacity: 0.6; }

textarea.field {
  height: auto;
  &.text {
    height: 201px;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    margin: 10px 0 0;
    padding: 8px 12px; } }

.dropdown.bootstrap-select.field {
  padding: 0;
  .bttn {
    border: 0;
    padding-left: 0;
    line-height: 40px; }
  div.dropdown-menu.show {
    border-radius: 0;
    border: 1px solid rgba(216,216,216,0.5); } }


.pass-input {
  position: relative; }
.eye-password {
  position: absolute;
  width: 20px;
  height: 16px;
  background: url('../images/icon-eye-hide.svg') no-repeat center;
  background-size: 100% auto;
  right: 13px;
  top: 13px;
  cursor: pointer;
  //opacity: 0.5
  &:hover {}
  opacity: 1;
  &.show {}
  background-image: url('../images/icon-eye-show.svg');
  opacity: 1; }

.field-group {
  position: relative;
  .field {
    padding-right: 120px; }
  .dropdown.bootstrap-select {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #e2e2e2;
    .bttn {
      border: none;
      width: 110px; } } }




.date {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 20px;
    right: 13px;
    top: 10px;
    background: url("../images/ic-calendar-small.svg") no-repeat center;
    height: 20px; }
  .field {
    padding-right: 46px; } }

.radio {
  margin-top: 4px;
  position: relative;
  display: inline-block;
  label {
    margin: 0;
    cursor: pointer; }
  div {
    padding-left: 28px;
    &:before,&:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #e2e2e2;
      background: #fff; }
    &:after {
      width: 8px;
      height: 8px;
      top: 6px;
      left: 6px;
      border: none; } }

  input {
    position: absolute;
    top: 0;
    width: 0;
    visibility: hidden;
    &:checked + div {
      &:before {
        background: #1565c0; } } }
  &+ .radio {
    margin-left: 24px; } }

.checkbox-group {
 }  // padding: 12px 0 0
.checkbox {
  position: relative;
  padding: 8px 0;
  label {
    margin: 0;
    cursor: pointer;
    font-weight: 400;
    position: relative; }
  div label {
    padding-left: 28px;
    &:before,&:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      width: 17px;
      height: 17px;
      border-radius: 4px;
      border: 1px solid #E2E2E2;
      background: #fff; }
    &:after {
      content: "\f00c";
      font-family: fontAwesome;
      color: #fff;
      text-align: center;
      font-size: 10px;
      line-height: 17px;
      border: none;
      background: none; } }
  input {
    position: absolute;
    top: 0;
    width: 0;
    visibility: hidden;
    &:checked + div label {
      &:before {
        background: $red;
        border-color: darken($red, 5%); } } } }

.dropdown.bootstrap-select {
  position: relative;
  select {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 0.5px;
    height: 100%;
    padding: 0;
    opacity: 0;
    border: none;
    visibility: hidden; }
  .bttn {
    cursor: pointer;
    background: none;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 42px;
    width: 100%;
    text-align: left;
    padding: 0 32px 0 12px;
    outline: none !important;
    &:after {
      content: "\f107";
      font-family: fontAwesome;
      line-height: 31px;
      top: calc(50% - 15px);
      right: 10px;
      position: absolute; } }
  div.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #AFAFAF;
    border-radius: 4px;
    margin: 2px 0 0;
    width: 100%;
    min-width: 100px;
    box-shadow: 0 16px 40px 0 rgba(0,0,0,0.25);
    a {
      display: block;
      padding: 10px 12px;
      color: #485460;
      font-weight: 400;
      cursor: pointer;
      font-size: 14px;
      line-height: 20px; }
    li {
      position: relative;
      .fa {
        display: none; }
      &:hover {
        a {
          background: rgba(#000,0.05); } }
      &.selected,&.active {
        a {
          background: #F9F9F9; } }
      &:not(:last-child) {
 } }        //border-bottom: 1px solid #D8D8D7
    &.show {
      display: block; } }
  &.show {
    .bttn {
      border-color: #1565BE; } } }


.bs-searchbox {
  padding: 10px;
  background: #eee;
  .form-control {
    width: 100%;
    height: 40px;
    border: 1px solid #000;
    padding: 6px 12px; } }

li.no-results {
  padding: 10px; }

.form-action {
  margin-top: 24px;
  .btn {
    min-width: 184px;
    cursor: pointer; } }
label.file_label {
  display: inline-block;
  position: relative;
  margin-right: 12px; }

.file {
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    visibility: hidden; }
  div {
    line-height: 40px;
    border-radius: 2px;
    // background-color: #D8D8D8
    border: 1px solid #e2e2e2;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 0 30px;
    display: inline-block;
    cursor: pointer;
    margin: 8px 0;
    transition: all .3s;
    @include ellipsis;
    &:hover {
      background: #e2e2e2; } }
  &.has-file div {
    padding: 0 15px;
    font-weight: normal; } }


.field-row {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  .field {
    border: none; }
  .dropdown.bootstrap-select {
    .bttn {
      border: none; } }
  [class*="col-4"] {
    &:not(:last-child) {
      border-right: 1px solid #e2e2e2; } } }



// GRAVITY FORM
.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
  display: flex;
  flex-wrap: wrap;
 }  // column-count: 2

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
  display: block;
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%; }

.gform_wrapper.gf_browser_chrome .gfield_checkbox li input,
.gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox],
.gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio] {
  position: absolute !important;
  opacity: 0 !important;
  visibility: hidden !important; }
body .gform_wrapper .gfield_checkbox li input,
body .gform_wrapper .gfield_checkbox li input[type=checkbox],
body .gform_wrapper .gfield_radio li input[type=radio] {
  position: absolute !important;
  opacity: 0 !important;
  visibility: hidden !important; }

body .gform_wrapper {
  .gfield_checkbox li label,
  .gfield_radio li label {
    font-size: 18px;
    line-height: 26px !important;
    padding-left: 28px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      width: 17px;
      height: 17px;
      border-radius: 4px;
      border: 1px solid #E2E2E2;
      background: #fff; }
    &:after {
      content: "\f00c";
      font-family: fontAwesome;
      color: #fff;
      text-align: center;
      font-size: 10px;
      line-height: 17px;
      border: none;
      background: none; } } }

body .gchoice_select_all,
body .gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked+label,
body .gform_wrapper ul.gfield_radio li input[type=radio]:checked+label {
  font-weight: normal;
  &:before {
    background: #CA0538;
    border-color: #b10431; } }

.gform_wrapper .top_label .gfield_label, .gform_wrapper legend.gfield_label {
  color: #636362;
  font-weight: 400;
  margin: 0;
  -webkit-appearance: none;
  font-size: 18px;
  line-height: 26px !important;
  display: block !important; }

.gform_wrapper label.gfield_label, .gform_wrapper legend.gfield_label {
  font-weight: 400; }

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
  @extend .field; }

.gform_wrapper .dropdown.bootstrap-select {
  border-bottom: 1px solid rgba(216, 216, 216, 0.5);
  .bttn {
    border: 0;
    padding-left: 0;
    font-size: 18px; }
  div.dropdown-menu {
    border-radius: 0;
    border: 1px solid rgba(216, 216, 216, 0.5); } }

.gform_wrapper textarea.medium {
  height: 201px;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  margin: 10px 0 0;
  padding: 8px 12px;
  background-color: transparent; }

.gform_wrapper .gform_footer {
  padding: 0 !important;
  margin-top: 32px !important;
  text-align: right; }

body .gform_wrapper .top_label div.ginput_container {
  margin: 0 !important; }

body .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] {
  width: 100%;
  display: block;
  margin: 0;
  min-width: 184px;
  @extend .btn;
  line-height: 48px !important;
  font-family: "TradeGothic LT", sans-serif;
  text-transform: uppercase; }


.gform_confirmation_message {
  padding: 10px;
  border-radius: 2px;
  background: #f9f9f9;
  border: 1px solid #e2e2e2;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  font-size: 16px;
  line-height: 24px;
  .danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; } }

.ginput_container_fileupload {
  .validation_message {
    display: none; } }

body .gform_wrapper .gfield_description {
  display: block;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 0 0 !important;
  opacity: .6; }

body .gform_wrapper div.validation_error {
  display: none; }

body .gform_wrapper li.gfield.gfield_error,
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  background-color: transparent;
  margin-bottom: 6px!important;
  border: 0;
  padding: 0; }

body .gform_wrapper .validation_message {
  font-weight: normal;
  color: #d63031;
  opacity: 1;
  position: relative;
  &:before {
    content: "\f06a";
    font-family: "fontAwesome";
    display: inline-block;
    margin-right: 6px; } }

body .gform_wrapper .gfield_error .gfield_label,
body .gform_wrapper li.gfield_error div.ginput_complex.ginput_container label,
body .gform_wrapper li.gfield_error ul.gfield_checkbox,
body .gform_wrapper li.gfield_error ul.gfield_radio {
  color: #636362; }

body .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
  border-color: transparent;
  border-bottom-color: rgba(216,216,216,0.5); }
body .gform_wrapper li.gfield_error textarea {
  border-color: rgba(216,216,216,0.5); }
